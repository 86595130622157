import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LessonSettingService from "../service/lessonSetting";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useLessonSetting(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const lessonSettingService = new LessonSettingService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 lessonSetting join해서 가져온다.
  const lessonSettingQuery = useQuery(
    ["lessonSetting"],
    () => lessonSettingService.findAllLessonSetting(conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("lessonSetting Query Data:", data);
      // },
    }
  );

  const createLessonSetting = useMutation(
    (data) => lessonSettingService.createLessonSetting(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["lessonSetting"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateLessonSetting = useMutation(
    (data) => lessonSettingService.updateLessonSetting(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lessonSetting"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating lessonSetting:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteLessonSetting = useMutation(
    (data) => lessonSettingService.deleteLessonSetting(data),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["lessonSetting"]);
      },
    }
  );

  return {
    lessonSettingQuery,
    createLessonSetting,
    updateLessonSetting,
    deleteLessonSetting,
  };
}
