import React from "react";
import styles from "./CSS/LoadingInside.module.css";

export default function LoadingInside() {
  return (
    <div className={styles.container}>
      <span className={styles.message}>로딩 중 입니다</span>
      <div className={styles.dots}>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
      </div>
    </div>
  );
}
