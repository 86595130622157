import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TagListService from "../service/tagList";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useTagList(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const tagListService = new TagListService(httpClient);

  //users db에서 TagList join해서 가져온다.
  const tagListByEntityQuery = useQuery(
    ["tagListByEntity", conditions],
    () => tagListService.findAllTagListByEntity(conditions),
    {
      enabled: !!conditions.entity_type,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("Query Data: tagListByEntity", data);
      // },
      // onError: () => {
      //   console.log("이게 대체 왜 실행돼?");
      // },
    }
  );

  const tagListQuery = useQuery(
    ["tagList", conditions],
    () => tagListService.findAllTagList(conditions),
    {
      enabled: !conditions.entity_type,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("Query Data: tagListQuery", data);
      // },
    }
  );

  const createOrUpdateTagList = useMutation(
    (tag) => tagListService.createOrUpdateTagList(tag),
    {
      onSuccess: () => queryClient.invalidateQueries(["tagList"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const deleteTagList = useMutation((id) => tagListService.deleteTag(id), {
    onSuccess: () => {
      // Refetch the query first
      queryClient.invalidateQueries(["educationalActivities"]);
    },
  });

  return {
    tagListByEntityQuery,
    tagListQuery,
    createOrUpdateTagList,
    deleteTagList,
  };
}
