import React from "react";
import { Link } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";
import MyProfile from "./MyProfile";
// import Notifications from "./Notifications";
import useUniComponent from "../../hooks/useUniComponent";
import { useAuthContext } from "../../context/AuthContext";
import HelpHyperLink from "../HelpHyperLink";
import LoadingModal from "../LoadingModal";
import { useUIContext } from "../../context/UIContext";

/* 
여기 들어가야 할 내용
1. 학교별 로고
2. 교직원 권한별 메뉴 드랍다운
3. 내정보 프로필
4. authentification context
5. 모바일일 때 sidebar로 바뀌기
*/

export default function MainNavbar() {
  const { user } = useAuthContext();

  const {
    commonMenus: allMenus,
    isLoading,
    error,
    userRole,
  } = useUniComponent();

  const { isLoadingModal } = useUIContext();

  return (
    <header
      style={{
        height: "65px",
        width: "98vw",
      }}
      className="container-fluid border-bottom border-2 d-none d-sm-flex ps-4"
    >
      {isLoadingModal && <LoadingModal />}
      <div className="row align-items-center w-100">
        <div className="col-1">
          <Link
            to={
              user?.schoolid ? `/${user.schoolid}/${userRole}/dashboard` : "/"
            }
            className="text-center align-self-center"
          >
            <img alt="에듀씨드" src="/images/eduseedLogo.png" height="50px" />
          </Link>
        </div>
        <div className="col-9 d-flex flex-wrap justify-content-around">
          {isLoading && <p>Loading...</p>}
          {error && <p>{error.message || error}</p>}
          {allMenus &&
            allMenus
              .filter(
                (menu) =>
                  menu.components === "Appbar" &&
                  menu.parentcategory === "parent" &&
                  menu.display === true
              )
              .sort(function (a, b) {
                return a.categoryorder - b.categoryorder;
              })
              .map((menu, key) => (
                <MenuDropdown
                  key={menu.id}
                  parentMenu={menu}
                  subMenus={allMenus}
                  user={user}
                />
              ))}
          <div>
            <button
              className="btn btn-sm"
              type="button"
              key="feedback"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSev67sNIFU22Ze3ADyb27mTBnKql_pOri8SyObqlnIH4lJw0A/viewform?usp=sf_link",
                  "_blank"
                )
              }
            >
              에러/의견제출
            </button>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-evenly">
          <MyProfile subMenus={allMenus} user={user} />
          {/* <Notifications subMenus={allMenus} user={user} /> */}

          {(user?.role === "teacher" || user?.role === "admin") && (
            <HelpHyperLink link={"https://youtu.be/hycTioZ4ucY"} />
          )}
        </div>
      </div>
    </header>
  );
}
