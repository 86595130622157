import React, { useEffect, useState } from "react";
import DropdownsForAttendanceStatistics from "./components/DropdownsForAttendanceStatistics";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { useLocation } from "react-router-dom";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import ChangeMonth from "./components/ChangeMonth";
import AttendanceStatisticsTable from "./components/AttendanceStatisticsTable";
import useAttendance from "../../../hooks/useAttendance";
import useSchoolYearPeriod from "../../../hooks/useSchoolYearPeriod";
import { useUIContext } from "../../../context/UIContext";
import LoadingInside from "../../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import { useAuthContext } from "../../../context/AuthContext";

export default function AttendanceStatistics() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { isLoading, data: registrationInfoData },
  } = useRegistrationInfo(conditions);

  const { attendanceQuery } = useAttendance(conditions);

  const {
    schoolYearPeriodQuery: { data: schoolYearPeriodData },
  } = useSchoolYearPeriod(conditions);

  const { setAlertModalState } = useUIContext();

  const { hasPermission } = useAuthContext();

  //필터를 위한 모음
  const [organSettingInfo, setOrganSettingInfo] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    selectedStudent: "",
    organPathForSort: "/",
  });

  const [rows, setRows] = useState([]);
  const [attendanceRecord, setAttendanceRecord] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (organSettingInfo.schoolYear === null) {
      if (registrationInfoData && settingData && settingData.length > 0) {
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];

        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
        setOrganSettingInfo((organSettingInfo) => ({
          ...organSettingInfo,
          schoolYear: initialSchoolYear,
          schoolYearId: initialSchoolYearId,
        }));
        //rows 데이터처리
        if (initialSchoolYear) {
          const sortedRows = generateRegistrationRows(
            registrationInfoData,
            settingData,
            initialSchoolYear,
            organSettingInfo.organPathForSort
          );

          setRows(sortedRows);
          // console.log("sortedRows", sortedRows);
        }
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("settingData", settingData);

        //사실 Mui dataGrid row용으로 만든 건데, 유용해서 계속 쓰자.
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          organSettingInfo.schoolYear,
          organSettingInfo.organPathForSort
        );
        setRows(sortedRows);
        // console.log("sortedRows2", sortedRows);
      }
    }

    // attendanceData를 학년도기준으로 불러와야 함
    // 그렇게 불러온 다음에 프론트에서 과정 학년, 반, 월별로 필터해서 보여주면 되겠군
    // 아니야 불러오는 데이터가 많고 복잡하니 다 불러와서 프론트에서 조합하자.

    // 그리고 앗싸리 서버에서 불러올 때 username을 기준으로 fullname, hierarchy_level을 가져오고 1년, 학기별 통계도 서버에서 만들어서 오자
    // 프론트에서는 해당학년도 학사설정이 안되어 있으면 관리자에게 하라고 하라고 alert 뜨게 하고.... / 이참에 alert 형식을 만들까?
    // 월별은 프론트에서 하자

    // 여기 dependency가 있어서 schoolYear와 organPathForSort 필터에 맞게 필터되어서 배포된다.
  }, [
    organSettingInfo.organPathForSort,
    organSettingInfo.schoolYear,
    organSettingInfo.schoolYearId,
    registrationInfoData,
    settingData,
  ]);

  useEffect(() => {
    // 데이터가 존재하고, 학년 ID가 정의되어 있으며, attendanceQuery도 사용 가능한 경우에만 실행
    if (
      organSettingInfo.schoolYearId !== null &&
      schoolYearPeriodData &&
      schoolYearPeriodData.length > 0 &&
      attendanceQuery
    ) {
      /* 
아 어렵다... termPeriod를 organSettingInfo에 따라서 filter 한 후 적합한 것을 써야 하는데...
머리가 안 돌아간다...
필요한 걸 하나씩 차근차근 생각해 보자.
1. (공통) 학년도별로 필터 한다. 
2. 과정 선택시 schoolPeriod에 과정 적용한 것으로 필터하는데, 과정 적용한 것이 없으면 전체 것이 나온다. 
3. 학년 선택시 schoolPeriod에 학년 적용한 것으로 필터하는데, 학년 적용한 것이 없으면 전체 것이 나온다. 

내가 직접 짜 보자.

아 그리고 배포하고 발견한 버그들
(1) 중복 저장된 것들이 많다. 저거 언제 다 찾아서 지우지... 
(2) 백엔드에도 중복 검사 후 저장하도록 하는것 적용하는게 좋겠다. 혹시라도 여러사람이 동시에 할 수도 있잖아.
(3) termperiod를 제대로 필터 안해서, 1학기가 3개 나왔다. (이거 filter 잘 해서 잘 나오게 해야 함)
(4) 출석만 세었다... 그러면 안되고 결석 아닌 것만 빼서 세어야 한다. 
*/

      let filteredPeriod = [];
      let hierarchyOnePeriod = [];
      let hierarchyTwoPeriod = [];

      filteredPeriod = schoolYearPeriodData.filter(
        (r) =>
          r.school_year_id.toString() ===
          organSettingInfo.schoolYearId.toString()
      );

      hierarchyOnePeriod = filteredPeriod.filter(
        (r) =>
          r.applied_hierarchy_one !== null &&
          r.applied_hierarchy_one === Number(organSettingInfo.hierarchyOneId)
      );

      if (hierarchyOnePeriod.length > 0) {
        filteredPeriod = hierarchyOnePeriod;
      }

      hierarchyTwoPeriod = filteredPeriod.filter(
        (r) =>
          r.applied_hierarchy_two !== null &&
          r.applied_hierarchy_two === Number(organSettingInfo.hierarchyTwoId)
      );

      if (hierarchyTwoPeriod.length > 0) {
        filteredPeriod = hierarchyTwoPeriod;
      }

      if (filteredPeriod.length > 0) {
        filteredPeriod = filteredPeriod.sort((a, b) => {
          // hierarchy_two_name이 "전체"일 경우 우선 정렬
          if (
            a.hierarchy_two_name === "전체" &&
            b.hierarchy_two_name !== "전체"
          ) {
            return -1; // a가 b보다 앞에 오도록
          } else if (
            a.hierarchy_two_name !== "전체" &&
            b.hierarchy_two_name === "전체"
          ) {
            return 1; // b가 a보다 앞에 오도록
          }

          // hierarchy_one_name이 "전체"일 경우 우선 정렬
          if (
            a.hierarchy_one_name === "전체" &&
            b.hierarchy_one_name !== "전체"
          ) {
            return -1;
          } else if (
            a.hierarchy_one_name !== "전체" &&
            b.hierarchy_one_name === "전체"
          ) {
            return 1;
          }

          return 0; // 그 외의 경우 순서 유지
        });
      }

      const thisYearDates =
        filteredPeriod.length > 0 ? filteredPeriod[0] : null;

      // const thisYearDates = schoolYearPeriodData.find(
      //   (r) =>
      //     r.school_year_id.toString() ===
      //     organSettingInfo.schoolYearId.toString()
      // );

      // console.log("organSettingInfo", organSettingInfo);

      // console.log("filteredPeriod", filteredPeriod);

      // console.log("thisYearDates", thisYearDates);

      // console.log("schoolYearPeriodData", schoolYearPeriodData);
      // console.log(
      //   "organSettingInfo.schoolYearId",
      //   organSettingInfo.schoolYearId
      // );

      // console.log("schoolYearPeriodData", schoolYearPeriodData);

      // console.log("thisYearDates", thisYearDates);

      // console.log("thisYearDates.start_date", thisYearDates.start_date);
      // console.log("thisYearDates.end_date", thisYearDates.end_date);

      // 학년 데이터가 존재하고, 정규 표현식을 통과한 경우에만 진행

      if (thisYearDates) {
        // console.log("thisYearDates", thisYearDates);

        if (
          /^\d{4}-\d{2}-\d{2}$/.test(thisYearDates.start_date) &&
          /^\d{4}-\d{2}-\d{2}$/.test(thisYearDates.end_date)
        ) {
          const queryConditions = {
            schoolid: schoolId,
            startdate: thisYearDates.start_date,
            enddate: thisYearDates.end_date,
          };
          attendanceQuery.mutate(queryConditions, {
            onSuccess: (data) => {
              setAttendanceRecord(data);
            },
            onError: (error) => {
              console.error(error);
              alert(error.message);
            },
          });
        } else {
          setAlertModalState({
            visible: true,
            message:
              "해당 학년도의 학사기간이 올바르게 설정되지 않았습니다. 시작 또는 종료 날짜가 유효하지 않습니다. 관리자에게 문의하세요.",
          });
        }
      } else {
        setAttendanceRecord([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organSettingInfo.schoolYearId,
    organSettingInfo.hierarchyOneId,
    organSettingInfo.hierarchyTwoId,
    schoolId,
  ]); // refetchData 함수는 useCallback에 의해 메모이제이션되어, organSettingInfo.schoolYearId가 변경될 때만 재생성됩니다.

  //아래 dropdown에 세트로 들어가야 할 애들이다.
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    const organPath = dataName + "/" + value;

    let lastIndex = organSettingInfo.organPathForSort.lastIndexOf("/");
    const previousPath = organSettingInfo.organPathForSort.substring(
      0,
      lastIndex
    );
    // 100%는 아니지만 적당히 작동하니까...
    // console.log("previousPath", previousPath);
    // console.log("organPath", organPath);

    if (!organPath.startsWith("nu")) {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        organPathForSort: organPath,
      }));
    } else {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        organPathForSort: previousPath,
      }));
    }

    if (name === "0") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "0",
      }));
    }

    if (name === "1") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
      }));
    }

    if (name === "2") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
      }));
    }

    if (name === "3") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyThreeId: value,
      }));
    }
  };

  const onChangeMonth = (e) => {
    setSelectedMonth(e.target.value);
  };

  const preNextMonth = (preNext) => {
    if (preNext === "pre") {
      setSelectedMonth((month) => (month > 1 ? month - 1 : 1));
    }

    if (preNext === "next") {
      setSelectedMonth((month) => (month < 12 ? month + 1 : 12));
    }
  };

  // Function to handle printing the statistics table
  const handlePrint = (schoolYear, selectedMonth) => {
    const printContent = document.getElementById("printArea").innerHTML;
    const printWindow = window.open("", "_blank", "height=600,width=800");

    printWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <title>${schoolYear}년 ${selectedMonth}월 출석통계</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
        <style>
          body { font-family: Arial, sans-serif; padding-right: 20px;}
          @page { size: landscape; margin: 10mm;} /* 가로 방향으로 인쇄 설정 */
          .page-break { page-break-after: always; } /* 페이지 브레이크 스타일 */

          .monthTitle { display: none; } /* 기본적으로 monthTitle 숨김 */
          @media print {
            .monthTitle { display: block; } /* 인쇄 시에만 monthTitle 표시 */
          }

  .secondTitle {
    margin-bottom: 10px;
  }

          /* 인쇄할 테이블의 스타일 */
          .tableStyle th {
            padding: 0px;
            text-align: center;
            background-color: #f0f0f0;
          }
          
          .tableStyle td {
            padding: 0px;
            text-align: center;
          }
          
          .tableStyle {
            font-size: 12px;
            width: 100%;
            border-collapse: collapse;
          }
  
          th, td { border: 1px solid black; } /* 모든 테이블 셀에 테두리 추가 */
        </style>
      </head>
      <body>
        ${printContent}
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
        </body>
      </html>
    `);

    printWindow.document.close();

    printWindow.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  // Function to handle exporting the statistics to Excel
  // const handleExport = () => {
  //   // exportToExcel('attendance-table');
  // };

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access")) {
    return (
      <SimpleAlertMessageBlock message="접근 권한이 있는 사람만 접근할 수 있습니다." />
    );
  }

  return (
    <>
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          <div className="row mb-4 mt-2">
            <div className="col-3">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">출석통계</h3>
              </div>
            </div>
            <div className="col-9 d-flex gap-2 align-items-center">
              <DropdownsForAttendanceStatistics
                settingData={settingData}
                handleDropdownChange={handleDropdownChange}
                organSettingInfo={organSettingInfo}
                selectedMonth={selectedMonth}
                onChangeMonth={onChangeMonth}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-7">
              <ChangeMonth preNextMonth={preNextMonth} />
            </div>
            <div className="col-5 d-flex justify-content-end">
              <button
                className="btn btn-info me-3"
                onClick={() =>
                  handlePrint(organSettingInfo.schoolYear, selectedMonth)
                }
              >
                인쇄
              </button>
              {/* <button className="btn btn-info" onClick={handleExport}>
                다운로드
              </button> */}
            </div>
          </div>
          <div id="printArea">
            {organSettingInfo.hierarchyOneId &&
              organSettingInfo.hierarchyOneId !== "0" &&
              // key 3의 유니크한 값들을 추출
              [...new Set(rows.map((row) => row.organization_path))].map(
                (uniqueKey) => {
                  // 각 유니크한 key 값에 대해 rows를 필터링
                  // console.log("rows", rows);
                  // console.log("uniqueKey", uniqueKey);

                  const filteredRows = rows.filter(
                    (row) => row.organization_path === uniqueKey
                  );
                  // console.log("filteredRows", filteredRows);
                  return (
                    <div key={uniqueKey} className="page">
                      <h4 className="monthTitle d-none">
                        {selectedMonth && selectedMonth}월 출석통계
                      </h4>
                      <div className="secondTitle">{`${filteredRows[0][1]} 과정 ${filteredRows[0][2]}학년 ${filteredRows[0][3]}반`}</div>
                      <AttendanceStatisticsTable
                        rows={filteredRows}
                        attendanceRecord={attendanceRecord}
                        organSettingInfo={organSettingInfo}
                        selectedMonth={selectedMonth}
                        conditions={conditions}
                      />
                      <div className="page-break"></div> {/* 페이지 분리 */}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </>
  );
}
