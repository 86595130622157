import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApprovalGroupsService from "../service/approvalGroups.js";
import HttpClient from "../network/http.js";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext.jsx";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useApprovalGroups(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const approvalGroupsService = new ApprovalGroupsService(httpClient);
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 approvalGroups join해서 가져온다.

  const approvalGroupsQuery = useQuery(
    ["approvalGroups", conditions],
    () => approvalGroupsService.findAllApprovalGroups(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("ApprovalGroups Query Data:", data);
      // },
    }
  );

  const createApprovalGroups = useMutation(
    (data) => approvalGroupsService.createApprovalGroups(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["approvalGroups"]),
      onError: (error) => {
        console.error("Error creatingApprovalGroups:", error);
      },
    }
  );

  const updateApprovalGroups = useMutation(
    (data) => approvalGroupsService.updateApprovalGroups(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["approvalGroups"]),
      onError: (error) => {
        console.error("Error updatingApprovalGroups:", error);
      },
    }
  );

  const deleteApprovalGroups = useMutation(
    (id) => approvalGroupsService.deleteApprovalGroups(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["approvalGroups"]);
      },
    }
  );

  return {
    approvalGroupsQuery,
    createApprovalGroups,
    updateApprovalGroups,
    deleteApprovalGroups,
  };
}
