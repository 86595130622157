import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EvalItemsService from "../service/settingEvalItems";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import { arrangeDataByHierarchy } from "../util/specialCase";
import React from "react";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useSettingEvalItems(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const evalItemsService = new EvalItemsService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 evalItems join해서 가져온다.
  const evalItemsQuery = useQuery(
    ["evalItems"],
    () => evalItemsService.findAllEvalItems(conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("evalItems Query Data:", data);
      // },
    }
  );

  const sortedData = React.useMemo(() => {
    if (evalItemsQuery.data && evalItemsQuery.data.length > 0) {
      return arrangeDataByHierarchy([...evalItemsQuery.data]);
    } else {
      return [];
    }
  }, [evalItemsQuery.data]);

  const createEvalItems = useMutation(
    (data) => evalItemsService.createEvalItems(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["evalItems"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateEvalItems = useMutation(
    (data) => evalItemsService.updateEvalItems(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["evalItems"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating evalItems:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteEvalItems = useMutation(
    (id) => evalItemsService.deleteEvalItems(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["evalItems"]);
      },
    }
  );

  const getEvalItemsByLessonId = useMutation((forWhere) =>
    evalItemsService.getEvalItemsByLessonId(forWhere)
  );

  return {
    evalItemsQuery: { ...evalItemsQuery, data: sortedData },
    createEvalItems,
    updateEvalItems,
    deleteEvalItems,
    getEvalItemsByLessonId,
  };
}
