import React, { useEffect, useState } from "react";
import useUsers from "../../hooks/useUsers";
import { useLocation } from "react-router-dom";
import UniTable from "../../unicomponents/UniTable";
import AddTeacher from "./components/AddTeacher";
import { MdCheck, MdSave } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { convertToYearMonthDayFormat } from "../../util/formatdate";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import AssignGroupsModal from "../../unicomponents/Modals/AssignGroupsModal";
import useGroups from "../../hooks/useGroups";
import useGroupsMembers from "../../hooks/useGroupsMembers";
import { useUIContext } from "../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";
import EditGroupsModal from "../../unicomponents/Modals/EditGroupsModal";

export default function TeacherInfo() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  const conditions = { schoolid: schoolId, role: "teacher" };
  const [rows, setRows] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState();
  const [successSave, setSuccessSave] = useState();
  const [isSaved, setIsSaved] = useState({});

  const [rowModesModel, setRowModesModel] = useState({});
  const [groupsMembersAll, setGroupsMembersAll] = useState([]);

  const {
    usersQuery: { isLoading, error, data: userData },
    updateUser,
    deleteUser,
  } = useUsers(conditions);

  const {
    groupsQuery: { data: groupsData },
  } = useGroups({
    schoolid: schoolId,
    group_type: "teacher",
  });

  const {
    groupsMembersAllQuery: { data: groupsMembersAllData },
    createGroupsMembers,
    updateGroupsMembers,
    deleteGroupsMembers,
  } = useGroupsMembers({ schoolid: schoolId, key: "all" });

  const { handleToastCenterTop } = useUIContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    const copiedData = { ...userData };
    const arrayData = Object.keys(copiedData).map((key) => ({
      id: parseInt(key),
      ...copiedData[key],
    }));
    setRows(arrayData);
  }, [userData]);

  useEffect(() => {
    setGroupsMembersAll(groupsMembersAllData);
  }, [groupsMembersAllData]);

  //2가지 방법을 시도해 볼 수 있겠다ㅣ.
  //1. 서버에 저장되는 것을 prop으로 보내서 process 그 함수 내에서 실행 되도록 하는것
  //2. handleSaveClick을 통해서 가져온 id로 화면상에서 검색해서 그 하위요소의 input, select 등의 value를 취합하여 return 하는 것
  // 1번 먼저 해보자.

  const updateData = (updatedRow) => {
    setIsUploading(true);
    // console.log(updatedRow);
    let updatedUserData = { ...updatedRow };
    if (updatedRow.birthday) {
      let formattedValue = convertToYearMonthDayFormat(updatedRow.birthday);
      updatedUserData = { ...updatedUserData, birthday: formattedValue };
    }

    updateUser.mutate(updatedUserData, {
      onSuccess: (successMessage) => {
        setSuccessSave(successMessage);
        setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: true }));
        setTimeout(() => {
          setSuccessSave(null);
          setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: false }));
        }, 4000);
        setIsUploading(false);
      },
    });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
    // console.log("updatedData", id);
  };

  const handleDeleteClick = (id) => {
    // Confirmation prompt
    if (
      window.confirm("정말 이 유저를 삭제하시겠습니까? 신중하게 생각하세요")
    ) {
      setIsUploading(true);
      deleteUser.mutate(id, {
        onSuccess: (successMessage) => {
          setRows(rows.filter((row) => row.id !== id));
          setIsUploading(false);
        },
      });
    } else {
      // User clicked 'Cancel', do nothing
      console.log("취소되었습니다.");
    }
  };

  const columns = [
    {
      field: "username",
      headerAlign: "center",
      headerName: "사용자아이디",
      align: "left",
      width: 250,
    },
    {
      field: "password",
      headerAlign: "center",
      headerName: "Password",
      width: 150,
      align: "center",
      type: "password",
      valueGetter: (params) => {
        return params.value ? "*******" : "*******";
      },
      editable: true,
    },
    {
      field: "fullname",
      headerAlign: "center",
      headerName: "이름",
      align: "center",
      width: 100,
      editable: true,
    },
    {
      field: "gender",
      headerAlign: "center",
      headerName: "성별",
      align: "center",
      width: 100,
      type: "singleSelect",
      valueOptions: ["남", "여"],
      editable: true,
    },
    {
      field: "birthday",
      headerAlign: "center",
      headerName: "생년월일",
      align: "center",
      width: 200,
      type: "date",
      valueGetter: (params) => {
        // params.value는 각 셀의 값입니다.
        // 이 값을 Date 객체로 변환합니다.
        return params.value ? new Date(params.value) : null;
      },
      editable: true,
    },
    {
      field: "status",
      headerAlign: "center",
      headerName: "상태",
      align: "center",
      width: 100,
      type: "singleSelect",
      getOptionValue: (value) => value.status,
      getOptionLabel: (value) => value.statusName,
      valueOptions: [
        { status: 1, statusName: "활성" },
        { status: 0, statusName: "비활성" },
      ],
      valueFormatter: (params) => (params.value === 1 ? "활성" : "비활성"),
      editable: true,
    },
    {
      field: "assignGroup",
      headerAlign: "center",
      headerName: "그룹 배정",
      align: "center",
      width: 180,
      renderCell: (params) => {
        // 사용자 그룹 필터링

        const filteredGroups = groupsMembersAll?.filter(
          (group) => group.username === params?.row?.username
        );
        const userGroups = filteredGroups?.map(
          (group) => group.groups.group_name
        );

        // console.log("filteredGroups", filteredGroups);

        // 그룹이 2개 이상일 경우에는 첫 2개까지만 표시하고, 그 이후는 '...'으로 대체
        const displayedGroups =
          userGroups?.length > 2
            ? `${userGroups.slice(0, 2).join(", ")}, ...`
            : userGroups?.join(", ");

        return (
          <button
            onClick={() => {
              setEditModalOpen(true);
              setSelectedUserGroups(filteredGroups);
              // setSelectedUserGroups(
              //   filteredGroups.map((group) => group.groups)
              // );
              setSelectedUser(params.row.username);
            }}
            className="btn btn-outline-dark btn-sm p-1"
            style={{
              fontSize: "0.8rem", // 글씨 크기 작게 조정
              whiteSpace: "nowrap", // 줄바꿈 방지
              overflow: "hidden", // 넘치는 텍스트 숨기기
              textOverflow: "ellipsis", // 너무 길면 '...'으로 표시
              maxWidth: "180px", // 버튼 너비 제한
            }}
          >
            {displayedGroups || "그룹 설정"}
          </button>
        );
      },
    },
    {
      field: "saveDelete",
      headerAlign: "center",
      headerName: "저장/삭제",
      align: "center",
      width: 100,
      type: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={isSaved[id] ? <MdCheck size={20} /> : <MdSave size={20} />}
          label="저장"
          className="saveDataBtn"
          onClick={() => handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<MdDelete size={20} />}
          label="삭제"
          onClick={() => handleDeleteClick(id)}
        />,
      ],
    },
  ];

  const saveGroupMembers = () => {
    selectedUserGroups.forEach((group) => {
      if (group.deleted && group.id) {
        handleDelete(group);
      } else if (group.updated) {
        handleUpdate(group);
      } else if (group.created) {
        handleCreate(group);
      }
    });
  };

  const handleCreate = (group) => {
    // 중복 검사: 같은 groups_id와 username이 이미 존재하는지 확인
    const isDuplicate = groupsMembersAll.some(
      (existingGroup) =>
        existingGroup.groups_id === group.groups_id &&
        existingGroup.username === selectedUser
    );

    if (isDuplicate) {
      alert("이미 해당 그룹에 사용자 정보가 존재합니다.");
      return;
    }

    const addDataSet = {
      schoolid: schoolId,
      groups_id: group.groups_id, // 전달된 그룹 ID 사용
      username: selectedUser,
      role: group.role || "member",
    };

    setIsUploading(true);
    createGroupsMembers.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        // console.log(successMessage);
        queryClient.invalidateQueries(["groupsMembersAll"]);
        setIsUploading(false);
        handleToastCenterTop("그룹에 성공적으로 배정되었습니다.");
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error);
        alert("그룹 생성 중 문제가 발생했습니다: " + error.message);
      },
    });
  };

  const handleUpdate = (group) => {
    const dataToUpdate = {
      id: group.id,
      role: group.role,
    };

    setIsUploading(true);
    updateGroupsMembers.mutate(dataToUpdate, {
      onSuccess: (successMessage) => {
        console.log(group.role + "로 변경되었음");
        console.log(successMessage);
        setIsUploading(false);
        queryClient.invalidateQueries(["groupsMembersAll"]);
        handleToastCenterTop("성공적으로 변경되었습니다.");

        // setGroupsMembersAll((rows) =>
        //   rows.map(
        //     (row) =>
        //       row.id === id
        //         ? { ...row, role: value } // id가 일치하면 role을 업데이트
        //         : row // 그렇지 않으면 그대로 반환
        //   )
        // );
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "그룹 업데이트 중 문제가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleDelete = (group) => {
    const id = group.id;

    if (!id) {
      return;
    }
    // console.log("delete id", id);
    setIsUploading(true);
    deleteGroupsMembers.mutate(id, {
      onSuccess: (successMessage) => {
        setIsUploading(false);
        handleToastCenterTop("그룹에서 삭제되었습니다.");

        queryClient.invalidateQueries(["groupsMembersAll"]);
        setGroupsMembersAll((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "그룹 삭제 중 문제가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  // const fieldArray = ["password", "fullname", "birthday", "gender", "status"];
  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>error</p>}
      {modalOpen && (
        <AssignGroupsModal
          setModalOpen={setModalOpen}
          groupsData={groupsData}
          setUserGroups={setUserGroups}
          userGroups={userGroups}
        />
      )}

      {editModalOpen && (
        <EditGroupsModal
          setEditModalOpen={setEditModalOpen}
          groupsData={groupsData}
          setSelectedUserGroups={setSelectedUserGroups}
          selectedUserGroups={selectedUserGroups}
          selectedUser={selectedUser}
          saveGroupMembers={saveGroupMembers}
        />
      )}

      <div className="ms-3 pe-3" style={{ width: "96vw" }}>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="d-flex">
              <h3 className="fw-bold mt-3">교직원 계정 생성</h3>
              <HelpHyperLink link={"https://youtu.be/eXhLf9ufVfM"} />
            </div>
            {success && <p>✅{success}</p>}
            <AddTeacher
              isUploading={isUploading}
              setSuccess={setSuccess}
              setIsUploading={setIsUploading}
              setModalOpen={setModalOpen}
              userGroups={userGroups}
              setUserGroups={setUserGroups}
            />
          </div>
          <div style={{ width: "94vw", overflowX: "auto", maxWidth: "1536px" }}>
            {successSave && <p>✅{successSave}</p>}
            {userData?.length > 0 && (
              <UniTable
                columns={columns}
                rows={rows}
                // fieldArray={fieldArray}
                exportedFileName="교직원명단"
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                setRows={setRows}
                updateData={updateData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
