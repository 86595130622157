import { useQuery } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import UniComponentsService from "../service/unicomponents";
import { useMemo } from "react";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useUniComponent() {
  const { user, permissions, address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const uniComponentsService = new UniComponentsService(httpClient);

  const userRole = user?.role === "admin" ? "teacher" : user?.role;

  const readCommonMenus = useQuery(
    ["commonMenus", user?.schoolid],
    () => uniComponentsService.readCommonMenus({ schoolid: user?.schoolid }),
    {
      enabled: !!user?.schoolid,
      staleTime: 1000 * 60 * 10, //10분,
    }
  );

  const commonMenus = useMemo(() => {
    const menuData = readCommonMenus.data;
    if (!menuData || !user || !permissions) return [];

    let resultMenus = [];

    if (user.role === "admin") {
      resultMenus = menuData.filter((menu) =>
        ["teacher", "admin", "all"].includes(menu.user_role)
      );
    } else if (user.role === "teacher") {
      // `menunames_id`로 접근 권한을 확인하여 `can_access`가 true인 메뉴만 표시
      const accessibleMenuIds = permissions
        .filter((perm) => perm.can_access)
        .map((perm) => perm.menunames_id);

      resultMenus = menuData.filter(
        (menu) =>
          // 권한이 있거나 `parentcategory`가 "profile"인 메뉴를 포함
          (accessibleMenuIds.includes(menu.id) ||
            menu.parentcategory === "profile" ||
            menu.parentcategory === "parent") &&
          menu.display === true &&
          (menu.user_role === userRole || menu.user_role === "all") &&
          (!menu.excluded_schools ||
            !menu.excluded_schools.includes(user.schoolid))
      );

      resultMenus = resultMenus.filter(
        (menu) => menu.categoryname !== "관리자"
      );
    } else {
      resultMenus = menuData.filter(
        (menu) =>
          menu.display === true &&
          (menu.user_role === userRole || menu.user_role === "all") &&
          (!menu.excluded_schools ||
            !menu.excluded_schools.includes(user.schoolid))
      );
      resultMenus = resultMenus.filter(
        (menu) => menu.categoryname !== "관리자"
      );
    }

    return resultMenus;
  }, [permissions, readCommonMenus.data, user, userRole]);

  return {
    commonMenus,
    isLoading: readCommonMenus.isLoading,
    error: readCommonMenus.error,
    userRole,
  };
}
