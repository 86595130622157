import { useMutation } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import AttendanceService from "../service/attendance";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useAttendance(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const attendanceService = new AttendanceService(httpClient);
  // const queryClient = useQueryClient();

  const attendanceQuery = useMutation((queryConditions) => {
    return attendanceService.findAttendanceWithDatesClassByConditions(
      queryConditions
    );
  });

  // console.log("initialSchoolYear2", initialSchoolYear);

  // 보내는 데이터
  const createAttendance = useMutation(
    (attendanceInfo) => attendanceService.createAttendance(attendanceInfo),
    {
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error creating:", error);
        // Add your error handling logic here
      },
    }
  );

  const updateAttendance = useMutation(
    (attendanceInfo) => attendanceService.updateAttendance(attendanceInfo),
    {
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating:", error);
      },
    }
  );

  // const deleteAttendance = useMutation(
  //   (id) => attendanceService.deleteAttendance(id),
  //   {
  //     onMutate: async (id) => {
  //       await queryClient.cancelQueries(["registrationInfoConditions"]);

  //       // Snapshot the previous value
  //       const previousData = queryClient.getQueryData([
  //         "registrationInfoConditions",
  //       ]);

  //       // Return the snapshotted value
  //       return { previousData };
  //     },
  //     onError: (error, id, context) => {
  //       // Rollback to the previous value
  //       queryClient.setQueryData(
  //         ["registrationInfoConditions"],
  //         context.previousData
  //       );
  //     },
  //     onSuccess: async (data, variables) => {
  //       // Refetch the query first
  //       await queryClient.invalidateQueries(["registrationInfoConditions"]);

  //       // Then update the data based on the refetched query
  //       queryClient.setQueryData(["registrationInfoConditions"], (old) =>
  //         old ? old.filter((d) => d.id !== variables) : []
  //       );
  //     },
  //   }
  // );

  return {
    attendanceQuery,
    createAttendance,
    updateAttendance,
  };
}
