import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import React from "react";
import SettingService from "../service/setting";
import { useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

//여기에 levelName 관련한 것도 추가한다.
export default function useOrganizationSetting(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const settingService = new SettingService(httpClient);
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();

  const arrangeData = (data) => {
    data.sort((a, b) => {
      if (a.hierarchy_level !== b.hierarchy_level) {
        return a.hierarchy_level - b.hierarchy_level;
      } else if (a.order !== b.order) {
        return b.order - a.order;
      } else {
        return a.school_year - b.school_year;
      }
    });

    let result = [];
    let map = data.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    for (let item of data) {
      if (item.parent_id === 0) {
        result.push(item);
      } else {
        let idx = result.indexOf(map[item.parent_id]);
        if (idx !== -1 && idx + 1 < result.length) {
          result.splice(idx + 1, 0, item);
        } else {
          result.push(item);
        }
      }
    }

    return result;
  };

  // console.log("organ conditions", conditions);
  const settingQuery = useQuery(
    ["settingConditions", schoolId],
    () => settingService.findAllSettingsByConditions({ schoolid: schoolId }),
    {
      staleTime: 1000 * 60 * 5, //5분,
    }
  );

  const sortedData = React.useMemo(() => {
    if (settingQuery.data && settingQuery.data.length > 0) {
      return arrangeData([...settingQuery.data]);
    } else {
      return [];
    }
  }, [settingQuery.data]);

  // 보내는 데이터
  const createOrganizationUnit = useMutation(
    (unitInfo) => settingService.createOrganUnit(unitInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["settingConditions"]),
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error(
          "같은 단계에 같은 그룹 이름은 안됩니다. Error creating:",
          error
        );
        alert(
          "같은 단계에 같은 그룹 이름은 안됩니다. Error creating: " + error
        );
        // Add your error handling logic here
      },
    }
  );

  const updateOrganizationUnit = useMutation(
    (unitInfo) => settingService.updateOrganUnit(unitInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settingConditions"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error(
          "같은 단계에 같은 그룹 이름은 안됩니다. Error updating:",
          error
        );
        alert(
          "같은 단계에 같은 그룹 이름은 안됩니다. Error updating: " + error
        );
        // Add your error handling logic here
      },
    }
  );

  const deleteOrganizationUnit = useMutation(
    (id) => settingService.deleteOrganUnit(id),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(["settingConditions"]);

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(["settingConditions"]);

        // Return the snapshotted value
        return { previousData };
      },
      onError: (error, id, context) => {
        // Rollback to the previous value
        queryClient.setQueryData(["settingConditions"], context.previousData);
      },
      onSuccess: async (data, variables) => {
        // Refetch the query first
        await queryClient.invalidateQueries(["settingConditions"]);

        // Then update the data based on the refetched query
        // setTimeout(() => {
        //   // Then update the data based on the refetched query
        //   queryClient.setQueryData(["settingConditions"], (old) =>
        //     old ? old.filter((d) => d.id !== variables) : []
        //   );
        // }, 1000);
      },
    }
  );

  const createPreUnits = useMutation(
    (preInfo) => settingService.createPreInfo(preInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["settingConditions"]),
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error creating:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const addHrTeacherToOrganUnit = useMutation(
    (hrTeacherInfo) => settingService.addHrTeacherToOrganUnit(hrTeacherInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settingConditions"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating hrTeacher:", error);
        alert("Error updating hrTeacher: " + error);
      },
    }
  );

  //levelName 테이블이다.
  const levelNameQuery = useQuery(
    ["settingLevelName", schoolId],
    () => settingService.findAllBySchool({ schoolid: schoolId }),
    {
      staleTime: 1000 * 60 * 5, //5분,
    }
  );

  // 보내는 데이터
  const createLevelName = useMutation(
    (newLevelArray) => settingService.createLevelName(newLevelArray),
    {
      onSuccess: () => queryClient.invalidateQueries(["settingLevelName"]),
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error creating:", error);
        alert("Error creating: " + error);
        // Add your error handling logic here
      },
    }
  );

  const updateLevelName = useMutation(
    (levelNamesArray) => settingService.updateLevelName(levelNamesArray),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settingLevelName"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating:", error);
        alert("Error updating: " + error);
        // Add your error handling logic here
      },
    }
  );

  return {
    settingQuery: { ...settingQuery, data: sortedData },
    createOrganizationUnit,
    updateOrganizationUnit,
    deleteOrganizationUnit,
    createPreUnits,
    levelNameQuery,
    updateLevelName,
    createLevelName,
    addHrTeacherToOrganUnit,
  };
}
