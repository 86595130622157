import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EducationalActivitiesService from "../service/educationalActivities";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useEducationalActivities(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const educationalActivitiesService = new EducationalActivitiesService(
    httpClient
  );
  // console.log("conditions", conditions);
  //users db에서 educationalActivities join해서 가져온다.
  const educationalActivitiesQuery = useQuery(
    ["educationalActivities", conditions],
    () => educationalActivitiesService.findAllEducationalActivities(conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.activity_type_id,
      // enabled: !conditions?.type,
      staleTime: 1000 * 10 * 1, //10초,
      // onSuccess: (data) => {
      //   console.log("activity Query Data:", data);
      // },
    }
  );

  const createEducationalActivities = useMutation(
    (activity) =>
      educationalActivitiesService.createEducationalActivities(activity),
    {
      onSuccess: () => queryClient.invalidateQueries(["educationalActivities"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateEducationalActivities = useMutation(
    (activity) =>
      educationalActivitiesService.updateEducationalActivities(activity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["educationalActivities"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating educationalActivities:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteEducationalActivities = useMutation(
    (id) => educationalActivitiesService.deleteEducationalActivities(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["educationalActivities"]);
      },
    }
  );

  //이건 다른 db(educational_activities_types)에서 가져온다. 1개 가져오기만 하는 것이라 따로 안 만듬
  const educationalActivitiesTypeQuery = useQuery(
    ["educationalActivitiesType", conditions || ""],
    () =>
      educationalActivitiesService.findAllEducationalActivitiesTypes(
        conditions
      ),
    {
      enabled: !!conditions?.type,
      staleTime: 1000 * 60 * 20, //20분,
      // onSuccess: (data) => {
      //   console.log("Type Query Data:", data);
      // },
    }
  );

  return {
    educationalActivitiesQuery,
    createEducationalActivities,
    updateEducationalActivities,
    deleteEducationalActivities,
    educationalActivitiesTypeQuery,
  };
}
