import React, { useEffect, useRef, useState } from "react";
import styles from "./CSS/InsertGroupMembersSidebar.module.css";
import { MdClose } from "react-icons/md";
import useGroupsMembers from "../hooks/useGroupsMembers";
import useUsers from "../hooks/useUsers";
import { useUIContext } from "../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";

//handleAddClick을 통해서 row 정보가 나가면, 외부 컴포넌트의 정보와 결합해서 서버에 create 하면 된다.

/*
사용자의 role을 외부에서 받아온다. / 인원수 상단에 나오기
그 role을 바탕으로 users 에서 사용자 정보를 가져오는데 활성화 상태 사용자 정보만 가져온다.
표에 display 하고 표는 이름으로 검색 가능 이름, 아이디, 회원구분, 추가버튼, 전체추가버튼 있음
추가버튼 누르면 왼쪽 멤버에 추가, 전체추가는 전체 추가. 이미 추가된 학생 미 추가
 -> 이거 작업 중

groups의 id를 외부에서 받아온다.
그 groups id를 바탕으로 groupsMembers에서 member 데이터를 가져온다. / 인원수 상단에 나오기
표에 display 하고 표는 이름으로 검색 할 수 있으면 이름, 아이디, 역할, 회원구분, 제거버튼 으로 구분
 - 역할의 리더와 멤버는 select로 해서 바꾸면 자동으로 update되도록 하자. (안내문구 있어야 함)
 - 제거 버튼 누르면 delete 실행
*/

export default function InsertGroupMembersSidebar({
  isOpen,
  toggleSidebar,
  groupId,
  schoolId,
  role,
  groupName,
}) {
  // 사이드바 DOM 노드를 참조하기 위한 ref 생성
  const sidebarRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      // 사이드바 외부 클릭을 감지
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // 사이드바가 열려있는 경우에만 닫기
        if (isOpen) {
          toggleSidebar();
        }
      }
    }
    // 클릭 이벤트 리스너 등록
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // 클린업 함수를 통해 이벤트 리스너 해제
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  const conditions = {
    schoolid: schoolId,
    role: role,
    status: 1,
  };

  //필터된 학생들 정보를 나오게 하려고
  const [groupsMembersRows, setGroupsMembersRows] = useState([]);
  const [userDataRows, setUserDataRows] = useState([]);

  const [isAdded, setIsAdded] = useState([]);
  const [isAddedAll, setIsAddedAll] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const queryClient = useQueryClient();

  const {
    usersQuery: { data: userData },
  } = useUsers(conditions);

  const {
    groupsMembersQuery: { data: groupsMembersData },
    createGroupsMembers,
    createGroupsMembersBulk,
    updateGroupsMembers,
    deleteGroupsMembers,
  } = useGroupsMembers({ schoolid: schoolId, groups_id: groupId });

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  useEffect(() => {
    setGroupsMembersRows(groupsMembersData);
  }, [groupsMembersData]);

  useEffect(() => {
    setUserDataRows(userData);
  }, [userData]);

  const handleAddClick = (user) => {
    if (groupsMembersRows.find((r) => r.username === user.username)) {
      alert("이미 배정된 학생입니다.");
      return;
    }

    //데이터베이스에 저장할 데이터 만들기
    const addDataSet = {
      schoolid: schoolId,
      groups_id: groupId,
      username: user.username,
      role: "member",
    };

    // console.log("addDataSet", addDataSet);
    setIsLoadingModal(true);
    createGroupsMembers.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        // console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded((prev) => [...prev, user.id]);
        setTimeout(() => {
          setIsAdded([]);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleAddAllClick = (groupsMembersRows) => {
    const bulkDataSets = [];
    const addedMembers = []; // 성공적으로 추가될 학생들의 이름을 저장할 배열
    const duplicateMembers = []; // 중복으로 추가되지 않은 학생들의 이름을 저장할 배열
    let message;

    userDataRows.forEach((user) => {
      const isDuplicate = groupsMembersRows.some(
        (r) => r.username === user.username
      );

      // console.log("groupsMembersRows", groupsMembersRows);
      // console.log("userDataRows", userDataRows);
      // console.log("isDuplicate", isDuplicate);

      if (isDuplicate) {
        duplicateMembers.push(user.fullname);
        return;
      }
      const addDataSet = {
        schoolid: schoolId,
        groups_id: groupId,
        username: user.username,
        role: "member",
      };

      addedMembers.push(user.fullname);
      bulkDataSets.push(addDataSet);
    });

    if (bulkDataSets.length > 0) {
      setIsLoadingModal(true);
      createGroupsMembersBulk.mutate(bulkDataSets, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          // 성공적으로 추가된 row.id들을 setIsAddedAll로 처리
          setIsAddedAll(true);
          setTimeout(() => {
            setIsAddedAll(false);
          }, 1500);

          message = `${
            addedMembers.length
          }명이 성공적으로 추가되었습니다. <br>- ${addedMembers.join(", ")}`;

          if (duplicateMembers.length > 0) {
            message = `${
              addedMembers.length
            }명이 성공적으로 추가되었습니다. <br>- ${addedMembers.join(
              ", "
            )} <br> 다음 ${
              duplicateMembers.length
            }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateMembers.join(
              ", "
            )}`;
          }
          handleToastCenterTop(message, 4000);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error);
          alert("에러 발생: " + error.message);
        },
      });
    } else {
      if (duplicateMembers.length > 0) {
        message = `다음 ${
          duplicateMembers.length
        }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateMembers.join(
          ", "
        )}`;
      }
      handleToastCenterTop(message, 4000);
    }
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteGroupsMembers.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["groupsMembers"]);
        setGroupsMembersRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleUpdate = (e) => {
    const { name, value, id } = e.target;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    const dataToUpdate = {
      id: id,
      [name]: value,
    };

    setIsLoadingModal(true);
    updateGroupsMembers.mutate(dataToUpdate, {
      onSuccess: (successMessage) => {
        console.log(value + "로 변경되었음");
        console.log(successMessage);
        setIsLoadingModal(false);
        setGroupsMembersRows((rows) =>
          rows.map(
            (row) =>
              row.id === id
                ? { ...row, role: value } // id가 일치하면 role을 업데이트
                : row // 그렇지 않으면 그대로 반환
          )
        );

        handleToastCenterTop(
          selectedText + "로 변경되어 저장되었습니다.",
          1000
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    if (value === "") {
      setUserDataRows(userData);
    } else {
      const filteredRows = userData.filter((row) =>
        row.fullname.includes(value)
      );

      setUserDataRows(filteredRows);
    }
  };

  /* 
@@ 1. 업데이트
@@ 1.5 ~명
@@ 2. 검색 - 필터
3. 그룹이름
*/

  return (
    <div
      className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
      ref={sidebarRef}
    >
      <div className="text-end mt-3">
        <MdClose size={27} type="button" onClick={toggleSidebar} />
      </div>

      <div className="row">
        <div className="col-7">
          <div className="row p-2 d-flex justify-content-center mb-2">
            <div
              className="col-10 p-2 text-center"
              style={{ backgroundColor: "white", borderRadius: "5px" }}
            >
              그룹명: {groupName}
            </div>
          </div>
          <div className="row ms-2 me-2 mt-1">
            <table className="table table-bordered text-center">
              <thead>
                <tr
                  className="table-secondary p-1"
                  style={{ fontSize: "14px" }}
                >
                  <th className="p-1 align-middle">이름</th>
                  <th className="p-1 align-middle">사용자아이디</th>
                  <th className="p-1 align-middle">역할</th>
                  <th className="p-0 align-middle">
                    총 {groupsMembersRows?.length}명
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }} className="align-middle">
                {groupsMembersRows?.map((row) => (
                  <tr key={row.id}>
                    <td className="p-1">{row?.user?.fullname}</td>
                    <td className="p-1" style={{ fontSize: "11px" }}>
                      {row?.username}
                    </td>
                    <td className="p-1">
                      <select
                        className="select-like-span"
                        onChange={handleUpdate}
                        id={row?.id}
                        name="role"
                        value={row?.role}
                      >
                        <option value="member">멤버</option>
                        <option value="leader">리더</option>
                      </select>
                    </td>
                    <td className="p-1">
                      {confirmDelete.includes(row.id) ? (
                        <>
                          <button
                            type="button"
                            onClick={() => handleDelete(row)}
                            className="btn btn-sm btn-danger p-1 pt-0 pb-0 ms-1"
                          >
                            확인
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCancelDelete()}
                            className="btn btn-sm p-1 pt-0 pb-0 ms-1 btn-secondary"
                          >
                            취소
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleConfirmDelete(row)}
                          className="btn btn-sm p-1 pt-0 pb-0 ms-1 btn-warning"
                        >
                          삭제
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-5">
          <div className="row p-2">
            <div className="col-4 p-2 text-end">이름검색</div>
            <div className="col-8 pe-3 mb-2">
              <input
                type="text"
                className="form-control"
                id="searchKeyword"
                name="searchKeyword"
                value={searchKey}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="row ms-2 me-2 mt-1">
            <table className="table table-bordered text-center">
              <thead>
                <tr
                  className="table-secondary p-1"
                  style={{ fontSize: "14px" }}
                >
                  <th className="p-1 align-middle">이름</th>
                  <th className="p-1 align-middle">사용자아이디</th>
                  <th className="p-1 align-middle">
                    <button
                      className={`btn btn-sm ${
                        isAddedAll ? "btn-success" : "btn-primary"
                      } p-1 pt-0 pb-0 ms-1`}
                      style={{ fontSize: "13px" }}
                      onClick={() => {
                        handleAddAllClick(groupsMembersData);
                      }}
                      disabled={isAddedAll ? true : false}
                    >
                      {isAddedAll ? (
                        <>
                          <span style={{ display: "block" }}>작업</span>
                          <span style={{ display: "block" }}>완료</span>
                        </>
                      ) : (
                        <>
                          <span style={{ display: "block" }}>모두</span>
                          <span style={{ display: "block" }}>배정</span>
                        </>
                      )}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }} className="align-middle">
                {userDataRows?.map((user) => (
                  <tr key={user.id}>
                    <td className="p-1">{user.fullname}</td>
                    <td className="p-1" style={{ fontSize: "11px" }}>
                      {user.username}
                    </td>
                    <td className="p-1">
                      <button
                        className={`btn btn-sm ${
                          isAdded.includes(user.id)
                            ? "btn-success"
                            : "btn-outline-primary"
                        } p-1 pt-0 pb-0 ms-1`}
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          handleAddClick(user);
                        }}
                        disabled={isAdded.includes(user.id) ? true : false}
                      >
                        {isAdded.includes(user.id) ? "완료" : "배정"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
