import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import UniTable from "../../unicomponents/UniTable";
import UpdateStuInfoModal from "./components/UpdateStuInfoModal";
import LoadingModal from "../../unicomponents/LoadingModal";
import useStudentInfo from "../../hooks/useStudentInfo";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import {
  createFilteredAndSortedOptions,
  uniqueDataOptionsKeys,
} from "../../util/dealingArrObj";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import { useAuthContext } from "../../context/AuthContext";
import { useUIContext } from "../../context/UIContext";
import LoadingInside from "../../unicomponents/LoadingInside";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import { checkHrteachers } from "../../util/checkPermissions";

export default function StudentInfo() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId, role: "student" };
  const [rows, setRows] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [filteredSetting, setFilteredSetting] = useState({});
  const [filterOrgan, setFilterOrgan] = useState({});
  const [idsForKey, setIdsForKey] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [studentInfo, setStudentInfo] = useState(false);
  const [organPathForSort, setOrganPathForSort] = useState("/");

  const {
    studentInfoQuery: { isLoading, data: studentData },
  } = useStudentInfo(conditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo(
    { schoolid: schoolId },
    { registrationInfo: false, hrTeachers: true }
  );

  const { handleToastCenterTop } = useUIContext();

  const { hasPermission } = useAuthContext();

  // useEffect(() => {
  //   console.log("studentData", studentData);
  //   setRows(studentData || []);
  //   // console.log("studentData", studentData);
  // }, [studentData]);

  useEffect(() => {
    if (schoolYear === null) {
      //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
      let initialSchoolYear = null;
      let initialSchoolYearId = null;
      if (settingData && settingData.length > 0) {
        // console.log("settingData", settingData);
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];
        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
      }
      setSchoolYear(initialSchoolYear);

      //드랍다운용이다.
      const filtered = settingData.filter(
        (r) => r.school_year === initialSchoolYear
      );
      setFilteredSetting(filtered);

      setFilterOrgan((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      const forIdsForKey = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(forIdsForKey);

      // console.log("initialSchoolYear", initialSchoolYear);
      // console.log("organPathForSort", organPathForSort);

      //rows 데이터처리
      if (studentData && settingData && initialSchoolYear) {
        const sortedRows = generateRows(
          studentData,
          initialSchoolYear,
          organPathForSort
        );
        setRows(sortedRows);
        // console.log("sortedRows1", sortedRows);
      }
    } else {
      //rows 데이터처리
      if (studentData && settingData) {
        // console.log("settingData", settingData);
        // console.log("studentData", studentData);

        const sortedRows = generateRows(
          studentData,
          schoolYear,
          organPathForSort
        );
        setRows(sortedRows);
        // console.log("sortedRows2", sortedRows);
      }
    }
  }, [settingData, schoolYear, studentData, organPathForSort]);

  function generateRows(studentData, schoolYear, organPathForSort = "/") {
    if (schoolYear === "전체") {
      return studentData;
    }
    let toBeRows = studentData
      .map((user) => ({
        ...user,
        registrationinfos: user.registrationinfos.filter((info) => {
          return info.school_year === schoolYear;
        }),
      }))
      .filter((user) => user.registrationinfos.length > 0)
      .sort((a, b) => {
        if (a.fullname < b.fullname) return -1;
        if (a.fullname > b.fullname) return 1;
        return 0;
      })
      .sort((a, b) => {
        if (
          a.registrationinfos[0].organization_path <
          b.registrationinfos[0].organization_path
        )
          return -1;
        if (
          a.registrationinfos[0].organization_path >
          b.registrationinfos[0].organization_path
        )
          return 1;
        return 0;
      });

    // console.log("organPathForSort", organPathForSort);

    if (organPathForSort !== "/" && !organPathForSort.startsWith("nu")) {
      toBeRows = toBeRows
        .map((user) => ({
          ...user,
          registrationinfos: user.registrationinfos.filter((info) =>
            info.organization_path.includes(organPathForSort)
          ),
        }))
        .filter((user) => user.registrationinfos.length > 0);
    }

    return toBeRows;
  }

  const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "id",
    filters: { hierarchy_level: 0, parent_id: 0 },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const organPath =
      e.target.selectedOptions[0].getAttribute("data-name") + "/" + value;

    setOrganPathForSort(organPath);
    // setRows(
    //   generateRows(studentData, schoolYear, organPath)
    // );

    if (name === "0") {
      const filtered = settingData.filter(
        (r) => r.school_year === selectedOptionText
      );
      setFilteredSetting(filtered);
      const uniqueOptions = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(uniqueOptions);
      setSchoolYear(selectedOptionText);
    }
    // console.log("addRegi", addRegi);
    setFilterOrgan((prevFilter) => {
      const newFilter = { ...prevFilter, [name]: value };
      // console.log("newFilter", newFilter);
      Object.keys(newFilter).forEach((key) => {
        if (Number(key) > Number(name)) {
          delete newFilter[key];
        }
      });
      return newFilter;
    });

    // console.log("filterOrgan2", filterOrgan);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    if (value === "") {
      setRows(studentData);
    } else {
      const filteredRows = studentData.filter((row) =>
        row.fullname.includes(value)
      );
      //   console.log("filteredRows", filteredRows);
      // console.log("studentData", studentData);

      setRows(filteredRows);
    }
  };

  const handleUpdateClick = (row) => {
    const rowInfo = {
      id: row.studentinfos?.[0]?.id || null,
      user_id: row.id || null,
      schoolid: row.schoolid || null,
      fullname: row.fullname || null,
      username: row.username || null,
      korean_id_no: row.studentinfos?.[0]?.korean_id_no || null,
      memo: row.studentinfos?.[0]?.memo || null,
      address: row.studentinfos?.[0]?.address || null,
      address_detail: row.studentinfos?.[0]?.address_detail || null,
      entrance_date: row.studentinfos?.[0]?.entrance_date || null,
      phone_number: row.studentinfos?.[0]?.phone_number || null,
      father_name: row.studentinfos?.[0]?.father_name || null,
      father_phone: row.studentinfos?.[0]?.father_phone || null,
      mother_name: row.studentinfos?.[0]?.mother_name || null,
      mother_phone: row.studentinfos?.[0]?.mother_phone || null,
      siblings: row.studentinfos?.[0]?.siblings || null,
      zipcode: row.studentinfos?.[0]?.zipcode || null,
      studentPhoto_link: row.studentinfos?.[0]?.studentPhoto_link || null,
      graduation_no: row.studentinfos?.[0]?.graduation_no || null,
      academic_record: row.studentinfos?.[0]?.academic_record || null,
      special_record: row.studentinfos?.[0]?.special_record || null,
    };
    setStudentInfo(rowInfo);
    setModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        field: "fullname",
        headerAlign: "center",
        headerName: "이름",
        align: "center",
        width: 90,
        renderCell: (params) => (
          <button
            className="btn btn-sm btn-outline-dark p-1 m-1"
            style={{
              "--bs-btn-font-size": ".80rem",
            }}
            onClick={() => {
              if (
                !hasPermission(
                  null,
                  "can_update",
                  checkHrteachers(
                    hrTeachersData,
                    params.row.username,
                    schoolYear
                  )
                )
              ) {
                handleToastCenterTop(
                  "현재 담임과 권한이 있는 사람만 수정할 수 있습니다."
                );
                return;
              }
              handleUpdateClick(params.row); // 권한이 있을 경우만 실행
            }}
          >
            {params.value}
          </button>
        ),
      },
      {
        field: "username",
        headerAlign: "left",
        headerName: "사용자아이디",
        align: "left",
        width: 120,
      },
      {
        field: "gender",
        headerAlign: "left",
        headerName: "성별",
        align: "left",
        width: 50,
      },
      {
        field: "birthday",
        headerAlign: "left",
        headerName: "생년월일",
        align: "left",
        width: 100,
      },
      {
        field: "idatschool",
        headerAlign: "left",
        headerName: "학번",
        align: "left",
        width: 80,
      },
      {
        field: "korean_id_no",
        headerAlign: "left",
        headerName: "주민번호",
        align: "left",
        width: 90,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].korean_id_no
            : null,
      },
      {
        field: "entrance_date",
        headerAlign: "left",
        headerName: "입학일",
        align: "left",
        width: 100,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].entrance_date
            : null,
      },
      {
        field: "home_address",
        headerAlign: "left",
        headerName: "주소",
        align: "left",
        width: 200,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? (params.row.studentinfos[0].address
                ? params.row.studentinfos[0].address
                : "") +
              " " +
              params.row.studentinfos[0].address_detail
            : "",
      },
      {
        field: "phone_number",
        headerAlign: "left",
        headerName: "학생연락처",
        align: "left",
        width: 90,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].phone_number
            : null,
      },
      {
        field: "siblings",
        headerAlign: "left",
        headerName: "형제",
        align: "left",
        width: 100,
        valueGetter: (params) =>
          params.row.studentinfos &&
          params.row.studentinfos.length > 0 &&
          params.row.studentinfos[0].siblings
            ? params.row.studentinfos[0].siblings.map(
                (sibling) => sibling.sname
              )
            : null,
      },
      {
        field: "father_name",
        headerAlign: "left",
        headerName: "부이름",
        align: "left",
        width: 80,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].father_name
            : null,
      },
      {
        field: "father_phone",
        headerAlign: "left",
        headerName: "부연락처",
        align: "left",
        width: 90,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].father_phone
            : null,
      },
      {
        field: "mother_name",
        headerAlign: "left",
        headerName: "모이름",
        align: "left",
        width: 80,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].mother_name
            : null,
      },
      {
        field: "mother_phone",
        headerAlign: "left",
        headerName: "모연락처",
        align: "left",
        width: 90,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].mother_phone
            : null,
      },
      {
        field: "memo",
        headerAlign: "left",
        headerName: "메모",
        align: "left",
        width: 120,
        valueGetter: (params) =>
          params.row.studentinfos && params.row.studentinfos.length > 0
            ? params.row.studentinfos[0].memo
            : null,
      },
    ],
    [handleToastCenterTop, hasPermission, hrTeachersData, schoolYear]
  );

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock message="담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다." />
    );
  }

  return (
    <>
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          {isUpLoading && <LoadingModal />}
          <div className="row mb-4">
            {modalOpen && (
              <UpdateStuInfoModal
                setModalOpen={setModalOpen}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                setIsUpLoading={setIsUpLoading}
                studentData={studentData}
              />
            )}
            <div className="col-4">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">학생 기본정보</h3>
                <HelpHyperLink link={"https://youtu.be/3o6ID300gcw"} />
              </div>
            </div>
            <div className="col-8 d-flex gap-2 align-items-center">
              <select className="form-control" name="0" onChange={handleChange}>
                {settingData?.length > 0 && options}
                <option value="all">전체</option>
              </select>
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item) => {
                  if (item === "0") return null;
                  return (
                    <select
                      key={item}
                      name={item}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="" data-name="">
                        {idsForKey[item][0]}
                      </option>
                      {filteredSetting
                        .filter(
                          (r) =>
                            r.parent_id ===
                            Number(filterOrgan[(Number(item) - 1).toString()])
                        )
                        .sort((a, b) => {
                          const aValue = a["order"];
                          const bValue = b["order"];
                          if (
                            typeof aValue === "number" &&
                            typeof bValue === "number"
                          ) {
                            // 숫자인 경우
                            return aValue - bValue;
                          } else {
                            // 문자열인 경우
                            return aValue.localeCompare(bValue);
                          }
                        })
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-name={option.organization_path}
                          >
                            {option.group_name}
                          </option>
                        ))}
                    </select>
                  );
                })}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 col-sm-2 text-center">
              <label htmlFor="searchKeyword" className="col-form-label">
                검색
              </label>
            </div>
            <div className="col-8 col-sm-3">
              <input
                type="text"
                id="searchKeyword"
                name="searchKeyword"
                className="form-control"
                placeholder="학생이름"
                value={searchKey}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div
            style={{
              width: "97vw",
              overflowX: "auto",
              maxWidth: "1536px",
            }}
          >
            {rows?.length > 0 && (
              <UniTable
                columns={columns}
                rows={rows}
                exportedFileName="학생기본정보"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
