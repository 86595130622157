/**
 * checkHrteachers: Check homeroom teacher status based on the provided data
 * @param {Array} hrTeacherData - Array of data containing homeroom teacher and student information
 * @param {string} [studentUsername=""] - (Optional) The username of the student to filter by
 * @param {string} [schoolYear=""] - (Optional) The school year to filter by
 * @returns {boolean} - True if the teacher is a homeroom teacher based on the given filters, otherwise false
 *
 * Usage:
 * - Without filters: Returns true if hrTeacherData is not empty
 * - With studentUsername: Filters hrTeacherData by student username
 * - With schoolYear: Filters hrTeacherData by school year
 * - With both filters: Returns true only if both conditions are satisfied
 */

export function checkHrteachers(
  hrTeacherData,
  studentUsername = "",
  schoolYear = ""
) {
  // console.log("hrTeacherData", hrTeacherData);
  // console.log("studentUsername", studentUsername);
  // console.log("schoolYear", schoolYear);

  if (!Array.isArray(hrTeacherData) || hrTeacherData.length === 0) {
    return false; // No data means not a homeroom teacher
  }

  // Initialize filteredData
  let filteredData = hrTeacherData;

  // If no filters are provided, return true if data exists
  if (!studentUsername && !schoolYear) {
    return filteredData.length > 0;
  }

  // Filter by schoolYear only
  if (schoolYear) {
    filteredData = filteredData.filter(
      (item) => item.schoolYear === schoolYear
    );

    // Return early if filteredData is empty
    if (filteredData.length === 0) {
      return false;
    }
  }
  // console.log("filteredData111", filteredData);

  // Filter by studentUsername only
  if (studentUsername) {
    filteredData = filteredData.filter(
      (item) => item.username === studentUsername
    );

    // console.log("filteredData222", filteredData);

    // Return early if filteredData is empty
    if (filteredData.length === 0) {
      return false;
    }
  }

  // Return the final result
  return filteredData.length > 0;
}
