import React, { useEffect, useState } from "react";
import LeftMenuSidebar from "../../unicomponents/LeftMenuSidebar";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import { useLocation } from "react-router-dom";
import HrTeacherOpinion from "./components/HrTeacherOpinion";
import useVariousRecordInputs from "../../hooks/useVariousRecordInputs";
import useVariousRecordLists from "../../hooks/useVariousRecordLists";
import ReadingRecord from "./components/ReadingRecord";
import VolunteerWorkRecord from "./components/VolunteerWorkRecord";
import EleReadingRecord from "./namusupComponents/EleReadingRecord";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import LoadingInside from "../../unicomponents/LoadingInside";
import { checkHrteachers } from "../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import { useAuthContext } from "../../context/AuthContext";

//자, 이제 봉사활동 기록을 복붙 수준으로 개발해 볼 수 있을까?
//그리고 사용법 영상 찍어서 업데이트 공지하고
//성적표 개발해야 한다.

export default function SchoolLifeRecords() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const pageUrl = paths[3];
  const conditions = {
    schoolid: schoolId,
  };
  const schoolIdPageUrl = {
    schoolid: schoolId,
    page_url: pageUrl,
  };

  const [inputQueryConditions, setInputQueryConditions] = useState({
    schoolid: schoolId,
    record_list_id: "",
  });

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  const {
    settingQuery: { isLoading, data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    variousRecordListsQuery: { data: variousRecordListsData },
  } = useVariousRecordLists(schoolIdPageUrl);

  const {
    variousRecordInputsQuery: { data: variousInputsData },
  } = useVariousRecordInputs(inputQueryConditions);

  const {
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo(conditions, {
    registrationInfo: false,
    hrTeachers: true,
  });

  const { hasPermission } = useAuthContext();

  useEffect(() => {
    if (variousRecordListsData) {
      setInputQueryConditions((prev) => ({
        ...prev,
        record_list_id: variousRecordListsData[0].id,
      }));

      setActiveMenu({
        menu_name: variousRecordListsData[0].record_name,
        menu_id: variousRecordListsData[0].id,
      });
    }
  }, [variousRecordListsData]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setInputQueryConditions((prev) => ({
        ...prev,
        record_list_id: item.id,
      }));
    }
  };

  function renderComponent() {
    const filteredInputData = variousInputsData
      .filter(
        (item) => item.record_list_id === inputQueryConditions.record_list_id
      )
      .sort((a, b) => {
        const orderA = parseInt(a.order, 10);
        const orderB = parseInt(b.order, 10);

        if (isNaN(orderA) && isNaN(orderB)) {
          return 0;
        } else if (isNaN(orderA)) {
          return 1;
        } else if (isNaN(orderB)) {
          return -1;
        } else {
          return orderA - orderB;
        }
      });
    switch (activeMenu.menu_name) {
      case "hrTeacherOpinion":
        return (
          <HrTeacherOpinion
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
            hrTeachersData={hrTeachersData}
          />
        );
      case "readingRecord":
        return (
          <ReadingRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
            hrTeachersData={hrTeachersData}
          />
        );
      case "volunteerWorkRecord":
        return (
          <VolunteerWorkRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
            hrTeachersData={hrTeachersData}
          />
        );
      case "eleReadingRecord":
        return (
          <EleReadingRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
            hrTeachersData={hrTeachersData}
          />
        );
      default:
        return (
          <HrTeacherOpinion
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
            hrTeachersData={hrTeachersData}
          />
        );
    }
  }

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock message="담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다." />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={variousRecordListsData}
              extraFunction={handleQueryCondition}
            />
          </div>
          <div className="col-12 col-md-10">
            {variousInputsData && renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}
