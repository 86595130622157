import { useQuery } from "@tanstack/react-query";
import FormSessionsService from "../service/formSessions";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useFormSessions(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const formSessionsService = new FormSessionsService(httpClient);
  // const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 formSessions join해서 가져온다.
  const formSessionsQuery = useQuery(
    ["formSessions", conditions],
    () => formSessionsService.findAllFormSessions(conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.form_id,
      staleTime: 1000 * 60 * 60, //60분,
      // onSuccess: (data) => {
      //   console.log("formSessions Query Data:", data);
      // },
    }
  );

  // const createFormSessions = useMutation(
  //   (data) => formSessionsService.createFormSessions(data),
  //   {
  //     onSuccess: () => queryClient.invalidateQueries(["formSessions"]),
  //     onError: (error) => {
  //       console.error("Error creating:", error);
  //     },
  //   }
  // );

  // const updateFormSessions = useMutation(
  //   (data) => formSessionsService.updateFormSessions(data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["formSessions"]);
  //     },
  //     onError: (error) => {
  //       // Handle the failure by displaying an error message or performing other actions
  //       console.error("Error updating formSessions:", error);
  //       alert(error);
  //       // Add your error handling logic here
  //     },
  //   }
  // );

  // const deleteFormSessions = useMutation(
  //   (data) => formSessionsService.deleteFormSessions(data),
  //   {
  //     onSuccess: () => {
  //       // Refetch the query first
  //       queryClient.invalidateQueries(["formSessions"]);
  //     },
  //   }
  // );

  return {
    formSessionsQuery,
    // createFormSessions,
    // updateFormSessions,
    // deleteFormSessions,
  };
}
