import React from "react";

export default function SimpleAlertMessageBlock({ message }) {
  return (
    <div
      style={{
        display: "flex",
        fontSize: "1rem",
        color: "#ff6f61",
        marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      <span style={{ marginRight: "8px" }}>⚠️</span>
      {message}
    </div>
  );
}
