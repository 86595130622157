import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EduActiStudentsService from "../service/eduActiStudents";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useEduActiStudents(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const eduActiStudentsService = new EduActiStudentsService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 eduActiStudents join해서 가져온다.
  const eduActiStudentsQuery = useQuery(
    ["eduActiStudents", conditions],
    () => eduActiStudentsService.findAllEduActiStudents(conditions),
    // console.log("conditions", conditions),
    {
      enabled:
        !!conditions?.schoolid &&
        !!conditions?.school_year &&
        !!conditions?.activity_type_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("EduActiStudents Query Data:", data);
      // },
    }
  );

  const getEduActiStudentsByUserId = useMutation((forWhere) =>
    eduActiStudentsService.getEduActiStudentsByUserId(forWhere)
  );

  const getAllEduActiStudentsByUserIdWithStartDate = useMutation((forWhere) =>
    eduActiStudentsService.getAllEduActiStudentsByUserIdWithStartDate(forWhere)
  );

  const createEduActiStudents = useMutation(
    (activity) => eduActiStudentsService.createEduActiStudents(activity),
    {
      onSuccess: () => queryClient.invalidateQueries(["eduActiStudents"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const createEduActiStudentsBulk = useMutation(
    (activities) =>
      eduActiStudentsService.createEduActiStudentsBulk(activities),
    {
      onSuccess: () => queryClient.invalidateQueries(["eduActiStudents"]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const updateEduActiStudents = useMutation(
    (activity) => eduActiStudentsService.updateEduActiStudents(activity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["eduActiStudents"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating eduActiStudents:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteEduActiStudents = useMutation(
    (id) => eduActiStudentsService.deleteEduActiStudents(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["eduActiStudents"]);
      },
    }
  );

  return {
    eduActiStudentsQuery,
    getEduActiStudentsByUserId,
    getAllEduActiStudentsByUserIdWithStartDate,
    createEduActiStudents,
    createEduActiStudentsBulk,
    updateEduActiStudents,
    deleteEduActiStudents,
  };
}
