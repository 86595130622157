import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SchoolYearPeriodService from "../service/schoolYearPeriod";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useSchoolYearPeriod(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const schoolYearPeriodService = new SchoolYearPeriodService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 schoolYearPeriod join해서 가져온다.
  const schoolYearPeriodQuery = useQuery(
    ["schoolYearPeriod"],
    () => schoolYearPeriodService.findAllSchoolYearPeriod(conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("schoolYearPeriod Query Data:", data);
      // },
    }
  );

  const createSchoolYearPeriod = useMutation(
    (data) => schoolYearPeriodService.createSchoolYearPeriod(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["schoolYearPeriod"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateSchoolYearPeriod = useMutation(
    (data) => schoolYearPeriodService.updateSchoolYearPeriod(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["schoolYearPeriod"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating schoolYearPeriod:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteSchoolYearPeriod = useMutation(
    (id) => schoolYearPeriodService.deleteSchoolYearPeriod(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["schoolYearPeriod"]);
      },
    }
  );

  return {
    schoolYearPeriodQuery,
    createSchoolYearPeriod,
    updateSchoolYearPeriod,
    deleteSchoolYearPeriod,
  };
}
