import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LessonsService from "../service/lessons";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useLessons(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const lessonsService = new LessonsService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 lessons join해서 가져온다.
  const lessonsQuery = useQuery(
    ["lessons"],
    () => lessonsService.findAllLessons(conditions),
    {
      // enabled: !conditions?.type,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("lessons Query Data:", data);
      // },
    }
  );

  const createLesson = useMutation(
    (activity) => lessonsService.createLesson(activity),
    {
      onSuccess: () => queryClient.invalidateQueries(["lessons"]),
      // onError: (error) => {
      //   console.error("Error creating:", error);
      // },
    }
  );

  const updateLesson = useMutation(
    (activity) => lessonsService.updateLesson(activity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lessons"]);
      },
      // onError: (error) => {
      //   // Handle the failure by displaying an error message or performing other actions
      //   console.error("Error updating lessons:", error);
      //   alert(error);
      //   // Add your error handling logic here
      // },
    }
  );

  const updateLessonTags = useMutation(
    (activity) => lessonsService.updateLessonTags(activity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lessons"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating lessons tags:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteLesson = useMutation((id) => lessonsService.deleteLesson(id), {
    onSuccess: () => {
      console.log("Mutation success, invalidating lessons query...");
      queryClient.invalidateQueries(["lessons"]).then(() => {
        console.log("Lessons query invalidated, should refetch...");
      });
    },
  });

  return {
    lessonsQuery,
    createLesson,
    updateLesson,
    updateLessonTags,
    deleteLesson,
  };
}
