import React from "react";

export default function EditGroupsModal({
  setEditModalOpen,
  groupsData,
  setSelectedUserGroups,
  selectedUserGroups,
  selectedUser,
  saveGroupMembers,
}) {
  const handleGroupChange = (group) => {
    setSelectedUserGroups((prevSelected) => {
      const existingGroup = prevSelected.find(
        (selectedGroup) => selectedGroup.groups_id === group.id
      );

      if (existingGroup) {
        // 이미 선택된 그룹
        return prevSelected.map((selectedGroup) =>
          selectedGroup.groups_id === group.id
            ? {
                ...selectedGroup,
                deleted: !selectedGroup.deleted,
                created: selectedGroup.created ? false : selectedGroup.created, // created가 true였으면 false로 변경
              }
            : selectedGroup
        );
      } else {
        // 새로 추가된 그룹
        return [
          ...prevSelected,
          {
            groups_id: group.id,
            role: "member",
            created: true,
            groups: group,
          },
        ];
      }
    });
  };

  const handleRoleChange = (groupId, role) => {
    setSelectedUserGroups((prevSelected) =>
      prevSelected.map((group) =>
        group.groups_id === groupId
          ? { ...group, role, updated: group.created ? false : true } // 새로 생성된 그룹은 updated 필요 없음
          : group
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveGroupMembers();
    setEditModalOpen(false); // 모달 닫기
  };

  return (
    <div className="modal-container">
      <div
        className="modalForUnit"
        style={{ padding: "20px", maxWidth: "400px" }}
      >
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>그룹 배정</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditModalOpen(false)}
            style={{
              width: "20px", // 버튼 크기 조정
              height: "20px",
              padding: "0", // 내부 여백 제거
              marginTop: "-8px", // 오른쪽 마진을 조정해 제목과 더 가까워 보이도록
            }}
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <p style={{ fontSize: "0.9rem", color: "#555" }}>
              사용자가 속할 그룹과 역할을 선택하세요
            </p>
            <ul className="list-unstyled">
              {groupsData.map((group) => {
                const selectedGroup = selectedUserGroups.find(
                  (selectedGroup) => selectedGroup.groups_id === group.id
                );
                const isChecked = selectedGroup
                  ? !selectedGroup.deleted // deleted가 true이면 체크 해제
                  : false; // 선택되지 않은 경우 기본적으로 해제 상태

                const role = selectedGroup ? selectedGroup.role : "member";

                return (
                  <li
                    key={group.id}
                    className="d-flex align-items-center mb-2"
                    style={{ gap: "10px" }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`group-${group.id}`}
                      checked={isChecked}
                      onChange={() => handleGroupChange(group)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`group-${group.id}`}
                      style={{ flexGrow: 1, marginBottom: "0" }}
                    >
                      {group.group_name}
                    </label>
                    <select
                      className="form-select form-select-sm"
                      style={{ width: "90px" }}
                      value={role}
                      onChange={(e) =>
                        handleRoleChange(group.id, e.target.value)
                      }
                      disabled={!isChecked} // 체크되지 않은 경우 비활성화
                    >
                      <option value="member">멤버</option>
                      <option value="leader">리더</option>
                    </select>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="modal-footer d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() => setEditModalOpen(false)}
            >
              취소
            </button>
            <button type="submit" className="btn btn-primary">
              그룹 배정 완료
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
