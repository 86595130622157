import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GroupsAuthoritiesService from "../service/groupsAuthorities.js";
import HttpClient from "../network/http.js";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext.jsx";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useGroupsAuthorities(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const groupsAuthoritiesService = new GroupsAuthoritiesService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 groupsAuthorities join해서 가져온다.

  const groupsAuthoritiesQuery = useQuery(
    ["groupsAuthorities", conditions],
    () => groupsAuthoritiesService.findAllGroupsAuthorities(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.groups_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("GroupsAuthorities Query Data:", data);
      // },
    }
  );

  const createOrUpdateGroupsAuthorities = useMutation(
    (data) => groupsAuthoritiesService.createOrUpdateGroupsAuthorities(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["groupsAuthorities"]),
      onError: (error) => {
        console.error("Error createOrUpdateGroupsAuthorities:", error);
      },
    }
  );

  return {
    groupsAuthoritiesQuery,
    createOrUpdateGroupsAuthorities,
  };
}
