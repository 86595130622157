import React, { useEffect, useMemo, useState } from "react";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import ChangeDate from "./components/ChangeDate";
import AttendanceTable from "./components/AttendanceTable";
import { useLocation } from "react-router-dom";
import {
  createFilteredAndSortedOptions,
  uniqueDataOptionsKeys,
} from "../../util/dealingArrObj";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import { dateToYearMonthDayFormat } from "../../util/formatdate";
import useAttendance from "../../hooks/useAttendance";
import LoadingModal from "../../unicomponents/LoadingModal";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import { checkHrteachers } from "../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../unicomponents/SimpleAlertMessageBlock";
import { useAuthContext } from "../../context/AuthContext";
import LoadingInside from "../../unicomponents/LoadingInside";

export default function Attendance() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  const conditions = { schoolid: schoolId };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { isLoading, data: registrationInfoData },
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo(conditions, { hrTeachers: true });

  const { attendanceQuery } = useAttendance(conditions);

  const [filteredSetting, setFilteredSetting] = useState({});
  const [schoolYear, setSchoolYear] = useState(null);
  const [filterOrgan, setFilterOrgan] = useState({});
  const [idsForKey, setIdsForKey] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredRegistrationInfo, setFilteredRegistrationInfo] = useState([]);
  const [dates, setDates] = useState([]);
  const [startEndDates, setStartEndDates] = useState({
    startdate: "",
    enddate: "",
  });
  const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
  const [attendanceRecord, setAttendanceRecord] = useState([]);
  const [isUpLoading, setIsUpLoading] = useState(false);

  const [saveAttendance, setSaveAttendance] = useState({});

  const { hasPermission } = useAuthContext();

  useEffect(() => {
    if (registrationInfoData) {
      const filtered = registrationInfoData.filter(
        (r) => r.status === "재학중"
      );
      // console.log("filtered", filtered);

      setFilteredRegistrationInfo(filtered);
    }
  }, [registrationInfoData]);

  useEffect(() => {
    if (schoolYear === null) {
      //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
      let initialSchoolYear = null;
      let initialSchoolYearId = null;
      if (settingData && settingData.length > 0) {
        // console.log("settingData", settingData);
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];
        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
      }
      setSchoolYear(initialSchoolYear);

      const filtered = settingData.filter(
        (r) => r.school_year === initialSchoolYear
      );
      setFilteredSetting(filtered);

      setFilterOrgan((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      const forIdsForKey = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(forIdsForKey);
    }
  }, [settingData, schoolYear]);

  useEffect(() => {
    if (hrTeachersData && schoolYear)
      if (checkHrteachers(hrTeachersData, "", schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === schoolYear
        );
        const splited = thisYearData[0].organization_path.split("/");

        // console.log("splited", splited);
        // console.log("filterOrgan", filterOrgan);

        setFilterOrgan((prevFilter) => ({
          ...prevFilter,
          1: splited[2],
          2: splited[3],
          3: splited[4],
        }));

        const queryConditions = {
          schoolid: schoolId,
          startdate: dateToYearMonthDayFormat(startEndDates.startdate),
          enddate: dateToYearMonthDayFormat(startEndDates.enddate),
        };

        setIsUpLoading(true);
        attendanceQuery.mutate(queryConditions, {
          onSuccess: (data) => {
            // console.log("Data", data);

            const rows = processRegistrationInfo(
              filteredRegistrationInfo,
              settingData,
              schoolYear,
              thisYearData[0].organization_path
            );
            setRows(rows);
            setAttendanceRecord(data);
            setIsUpLoading(false);
          },
          onError: (error) => {
            setIsUpLoading(false);
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(error.message);
          },
        });
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredRegistrationInfo,
    hrTeachersData,
    schoolId,
    schoolYear,
    settingData,
  ]);

  useEffect(() => {
    const now = new Date(currentDate); // 현재 날짜
    now.setHours(0, 0, 0, 0);
    const startOfWeek = new Date(
      now.setDate(now.getDate() - ((now.getDay() + 6) % 7))
    ); // 이번 주 월요일 (첫째날)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    //이번 주 일요일 (마지막날)

    const newDates = [...Array(7)].map((_, i) => {
      const date = new Date(startOfWeek);
      date.setDate(date.getDate() + i);

      return dateToYearMonthDayFormat(date); // 월/일 형식
    });

    setDates(newDates);
    setStartEndDates({
      startdate: startOfWeek,
      enddate: endOfWeek,
    });

    if (startEndDates.startdate !== startOfWeek) {
      setIsUpLoading(true);

      const queryConditions = {
        schoolid: schoolId,
        startdate: dateToYearMonthDayFormat(startOfWeek),
        enddate: dateToYearMonthDayFormat(endOfWeek),
      };

      attendanceQuery.mutate(queryConditions, {
        onSuccess: (data) => {
          // console.log("Data", data);

          setAttendanceRecord(data);
          setSaveAttendance({});
          // console.log("attendanceRecord updated", data);
          setIsUpLoading(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "id",
    filters: { hierarchy_level: 0, parent_id: 0, schoolid: schoolId },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  const handleChange = (e) => {
    // console.log("handleChange");
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const keys = Object.keys(idsForKey);
    const maxKey = Math.max(...keys.map(Number));

    const queryConditions = {
      schoolid: schoolId,
      startdate: dateToYearMonthDayFormat(startEndDates.startdate),
      enddate: dateToYearMonthDayFormat(startEndDates.enddate),
    };

    if (maxKey.toString() === name.toString()) {
      setIsUpLoading(true);
      attendanceQuery.mutate(queryConditions, {
        onSuccess: (data) => {
          // console.log("Data", data);

          const rows = processRegistrationInfo(
            filteredRegistrationInfo,
            settingData,
            schoolYear,
            e.target.selectedOptions[0].getAttribute("data-name") + "/" + value
          );
          setRows(rows);
          setAttendanceRecord(data);
          setIsUpLoading(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    } else {
      setRows([]);
    }

    if (name === "0") {
      const filtered = settingData.filter(
        (r) => r.school_year === selectedOptionText
      );
      setFilteredSetting(filtered);
      const uniqueOptions = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(uniqueOptions);
      setSchoolYear(selectedOptionText);
    }
    // console.log("addRegi", addRegi);
    setFilterOrgan((prevFilter) => {
      const newFilter = { ...prevFilter, [name]: value };
      // console.log("newFilter", newFilter);
      Object.keys(newFilter).forEach((key) => {
        if (Number(key) > Number(name)) {
          delete newFilter[key];
        }
      });
      return newFilter;
    });
  };

  const handleDateChange = () => {
    const queryConditions = {
      schoolid: schoolId,
      startdate: dateToYearMonthDayFormat(startEndDates.startdate),
      enddate: dateToYearMonthDayFormat(startEndDates.enddate),
    };

    setIsUpLoading(true);
    attendanceQuery.mutate(queryConditions, {
      onSuccess: (data) => {
        setAttendanceRecord(data);
        setIsUpLoading(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(error.message);
      },
    });
  };

  function processRegistrationInfo(
    filteredRegistrationInfo,
    settingData,
    schoolYear,
    organPath
  ) {
    if (filteredRegistrationInfo && settingData) {
      const toBeRows = filteredRegistrationInfo
        .filter(
          (r) =>
            r.school_year === schoolYear && r.organization_path === organPath
        )
        .map((row) => {
          const {
            organization_path,
            hr_teacher_username,
            user,
            ...otherFields
          } = row;
          // organization_path를 분해하여 필드 만들기
          const paths = organization_path.split("/").filter((path) => path);
          const pathFields = paths.reduce((acc, curr, index) => {
            const thisGroup = settingData.find((r) => r.id === Number(curr));

            if (thisGroup) {
              return {
                ...acc,
                [index]: thisGroup.group_name,
                ["order" + index]: thisGroup.order,
              };
            } else {
              console.error(`No group found with id: ${curr}`);
              return acc;
            }
          }, {});

          const userObject = { fullname: user.fullname, gender: user.gender };

          return {
            ...otherFields,
            ...pathFields,
            ...userObject,
            organization_path,
          };
        })
        .sort((a, b) => a.studentno - b.studentno);

      const sortedArray = toBeRows.sort((a, b) => {
        // 'order' 필드를 찾아서 역순으로 정렬
        const orderKeysA = Object.keys(a)
          .filter((key) => key.startsWith("order"))
          .sort();
        const orderKeysB = Object.keys(b)
          .filter((key) => key.startsWith("order"))
          .sort();

        // 같은 'order' 레벨에서 높은 값을 우선으로 정렬
        for (let i = 0; i < orderKeysA.length; i++) {
          if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
            return a[orderKeysA[i]] - b[orderKeysB[i]];
          }
        }

        // 모든 'order' 값이 같을 경우에는 그대로 유지
        return 0;
      });

      return sortedArray;
    }

    return []; // registrationInfoData 또는 settingData가 없는 경우
  }

  const refetchData = () => {
    const queryConditions = {
      schoolid: schoolId,
      startdate: dateToYearMonthDayFormat(startEndDates.startdate),
      enddate: dateToYearMonthDayFormat(startEndDates.enddate),
    };

    attendanceQuery.mutate(queryConditions, {
      onSuccess: (data) => {
        setAttendanceRecord(data);
        setIsUpLoading(false);
        setSaveAttendance({});
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(error.message);
      },
    });
  };

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock message="담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다." />
    );
  }

  return (
    <>
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          {isUpLoading && <LoadingModal />}
          {/* {error && error.toString()} */}
          <div className="row mb-4 mt-2">
            <div className="col-3">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">출석기록</h3>
                <HelpHyperLink link={"https://youtu.be/Wfs4pxwj6ZA"} />
              </div>
            </div>
            <div className="col-9 d-flex gap-2 align-items-center">
              <select className="form-control" name="0" onChange={handleChange}>
                {settingData?.length > 0 && options}
              </select>
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item) => {
                  if (item === "0") return null;
                  return (
                    <select
                      key={item}
                      name={item}
                      className="form-control"
                      onChange={handleChange}
                      value={filterOrgan[item]}
                    >
                      <option value="" data-name="">
                        {idsForKey[item][0]}
                      </option>
                      {filteredSetting
                        .filter(
                          (r) =>
                            r.parent_id ===
                            Number(filterOrgan[(Number(item) - 1).toString()])
                        )
                        .sort((a, b) => {
                          const aValue = a["order"];
                          const bValue = b["order"];
                          if (
                            typeof aValue === "number" &&
                            typeof bValue === "number"
                          ) {
                            // 숫자인 경우
                            return aValue - bValue;
                          } else {
                            // 문자열인 경우
                            return aValue.localeCompare(bValue);
                          }
                        })
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-name={option.organization_path}
                          >
                            {option.group_name}
                          </option>
                        ))}
                    </select>
                  );
                })}
            </div>
          </div>
          <div>
            <ChangeDate
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setAttendanceRecord={setAttendanceRecord}
              handleDateChange={handleDateChange}
            />
          </div>
          <div>
            <AttendanceTable
              rows={rows}
              dates={dates}
              idsForKey={idsForKey}
              attendanceRecord={attendanceRecord}
              setIsUpLoading={setIsUpLoading}
              schoolId={schoolId}
              refetchData={refetchData}
              setAttendanceRecord={setAttendanceRecord}
              saveAttendance={saveAttendance}
              setSaveAttendance={setSaveAttendance}
              hrTeachersData={hrTeachersData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
