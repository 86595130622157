import React, { useEffect, useRef, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import useAllForms from "../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import useComponentsToForms from "../../../hooks/useComponentsToForms";
import DynamicComponentsRenderer from "../../../unicomponents/formComponents/DynamicComponentsRenderer";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useUIContext } from "../../../context/UIContext";
import FiltersBar from "../../../unicomponents/Dropdowns/FilterBar";
import { useAuthContext } from "../../../context/AuthContext";
import { filterSettingsByStudentsPath } from "../../../util/specialCase";
import useFormSessions from "../../../hooks/useFormSessions";
import useFormResponses from "../../../hooks/useFormResponses";

export default function SelfReflection() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedStudent: "",
  });
  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [keyData, setKeyData] = useState({});
  const [registrationInfoData, setRegistrationInfoData] = useState([]);
  const [newSetting, setNewSetting] = useState([]);
  const [rows, setRows] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [dynamicState, setDynamicState] = useState({
    selfReflectionBtn: false,
    studentNoDisplay: true,
  });
  const [validateInput, setValidateInput] = useState({
    user: false,
    term: false,
  });

  const sessionType = "schoolTerm";

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "selfReflection",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const { user } = useAuthContext();

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData },
  } = useComponentsToForms(formQueryConditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const {
    formSessionsQuery: { data: formSessionsData },
  } = useFormSessions(formQueryConditions);

  const { getRegistrationInfoByUsername } = useRegistrationInfo();
  const getRegistrationInfoByUsernameRef = useRef(
    getRegistrationInfoByUsername
  );

  const { getFormResponsesByStudent, createOrUpdateFormResponsesBulk } =
    useFormResponses();
  const getFormResponsesByStudentRef = useRef(getFormResponsesByStudent);

  useEffect(() => {
    if (user?.username && settingData) {
      const forWhere = {
        schoolid: schoolId,
        username: user.username,
      };

      getRegistrationInfoByUsernameRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          //   console.log("lessonList", data);

          setRegistrationInfoData(data);
          //   console.log("data", data);

          setNewSetting(filterSettingsByStudentsPath(data, settingData));
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    }
  }, [schoolId, settingData, user?.username]);

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));
      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
      });
    }
  }, [allFormsData]);

  useEffect(() => {
    if (formSessionsData) {
      //   console.log("formSessionsData", formSessionsData);
      const filterByType = formSessionsData.filter(
        (r) => r.session_type === sessionType
      );

      const recentSession = filterByType.sort((a, b) => {
        // start_date가 null인 경우 처리
        if (a.start_date === null) return 1;
        if (b.start_date === null) return -1;

        // start_date를 Date 객체로 변환하여 비교
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);

        return dateA - dateB;
      });
      setSessionId(recentSession[0]?.id || "");
    }
  }, [formSessionsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialHierarchyOneId = "";
    let initialHierarchyTwoId = "";
    let initialHierarchyThreeId = "";
    let initialData = [];
    if (
      filters.schoolYear === null &&
      newSetting &&
      newSetting.length > 0 &&
      registrationInfoData
    ) {
      initialData = newSetting
        .sort((a, b) => a.hierarchy_level - b.hierarchy_level)
        .sort((a, b) => b.school_year - a.school_year);

      if (initialData.length < 1) {
        alert(
          "재적에 등록되지 않았거나 다른 오류가 있습니다. 담임 선생님이나 관리자에게 문의하세요."
        );
        return;
      }

      initialSchoolYear = initialData[0].school_year;
      initialSchoolYearId = initialData[0].id;
      initialHierarchyOneId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 1
      );
      initialHierarchyTwoId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 2
      );
      initialHierarchyThreeId = initialData.find(
        (r) => r.school_year === initialSchoolYear && r.hierarchy_level === 3
      );
      if (!initialHierarchyThreeId?.organization_path) {
        alert(
          "재적에 등록되지 않았습니다. 담임 선생님이나 관리자에게 문의하세요."
        );
        return;
      }

      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
        hierarchyOneId: initialHierarchyOneId?.id,
        hierarchyTwoId: initialHierarchyTwoId?.id,
        hierarchyThreeId: initialHierarchyThreeId?.id,
        selectedStudent: user?.username,
        organPathForSort:
          initialHierarchyThreeId.organization_path +
          "/" +
          initialHierarchyThreeId.id,
      }));

      //  이게 term이 과정이나 별로 다를때 오류를 일으켜서 일단 주석처리

      if (termPeriodData) {
        setTermIdByToday(
          termPeriodData,
          setTermId,
          initialSchoolYearId,
          initialHierarchyOneId?.id,
          initialHierarchyTwoId?.id
        );
      }

      // if (termPeriodData) {
      //   setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      // }

      if (initialSchoolYear) {
        //학생 리스트
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          initialSchoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && newSetting) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          newSetting,
          filters.schoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.schoolYear,
    registrationInfoData,
    newSetting,
    termId,
    termPeriodData,
    user?.username,
  ]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        // console.log("nowPeriod", nowPeriod);
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  useEffect(() => {
    if (studentList && filters.selectedStudent) {
      const selectedObject = studentList.find(
        (r) => r.username === filters.selectedStudent
      );
      if (currentPeriod) {
        setKeyData({
          ...selectedObject,
          currentPeriod: currentPeriod,
        });
      } else {
        setKeyData(selectedObject);
      }
    } else {
      setKeyData({});
    }
  }, [currentPeriod, filters.selectedStudent, studentList]);

  useEffect(() => {
    if (
      filters?.selectedStudent &&
      formQueryConditions.form_id &&
      sessionId &&
      filters.schoolYearId &&
      termId
    ) {
      const forWhere = {
        schoolid: schoolId,
        form_id: formQueryConditions.form_id,
        username: filters.selectedStudent,
        session_id: sessionId,
        school_year_id: filters.schoolYearId,
        school_term_id: termId,
      };

      //   console.log("forWhere", forWhere);
      getFormResponsesByStudentRef.current.mutate(forWhere, {
        onSuccess: (data) => {
          //   console.log("formResponses", data);
          setRows(data);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    }
  }, [
    filters.schoolYearId,
    filters.selectedStudent,
    formQueryConditions.form_id,
    schoolId,
    sessionId,
    termId,
  ]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
      setRows([]);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));
      setRows([]);
      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));
      setRows([]);

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));
      setRows([]);
    }

    if (name === "students") {
      //   console.log("value", value);
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  const handleChangeToSave = (e) => {
    const {
      name: components_to_forms_id,
      value,
      type: inputType,
      checked,
    } = e.target;
    const schoolid = schoolId;
    const form_id = formQueryConditions.form_id;
    const username = keyData?.username;
    const session_id = sessionId;
    const school_year_id = filters.schoolYearId;
    const school_term_id = termId;
    const response = inputType === "checkbox" ? value : e.target.value;
    const question = e.target.getAttribute("data-question");

    if (filters.selectedStudent.length < 1) {
      handleToastCenterTop("학생을 선택하세요.");
      setValidateInput((prev) => ({ ...prev, user: true }));
      setTimeout(() => {
        setValidateInput((prev) => ({ ...prev, user: false }));
      }, 3000);

      return;
    }

    // console.log("termId", termId);

    if (!termId) {
      handleToastCenterTop("학기를 선택하세요.");
      setValidateInput((prev) => ({ ...prev, term: true }));
      setTimeout(() => {
        setValidateInput((prev) => ({ ...prev, term: false }));
      }, 3000);
      return;
    }

    setRows((preRows) => {
      const existingItem = preRows.find(
        (item) =>
          item.components_to_forms_id.toString() ===
            components_to_forms_id.toString() &&
          item.session_id.toString() === session_id.toString() &&
          item.school_year_id.toString() === school_year_id.toString() &&
          item.username === username &&
          item.school_term_id.toString() === school_term_id.toString()
      );

      if (existingItem) {
        return preRows.map((item) => {
          if (
            item.components_to_forms_id.toString() ===
              components_to_forms_id.toString() &&
            item.session_id.toString() === session_id.toString() &&
            item.school_year_id.toString() === school_year_id.toString() &&
            item.username === username &&
            item.school_term_id.toString() === school_term_id.toString()
          ) {
            const newResponse =
              inputType === "checkbox"
                ? handleCheckboxResponse(item.response, value, checked)
                : response;
            return {
              ...item,
              response: newResponse,
              isModified: true,
            };
          }
          return item;
        });
      } else {
        return [
          ...preRows,
          {
            schoolid,
            form_id,
            components_to_forms_id,
            username,
            session_id,
            school_year_id,
            school_term_id,
            response: inputType === "checkbox" ? [value] : response,
            question,
            isModified: true,
          },
        ];
      }
    });
    // console.log("rows", rows);
  };

  const handleCheckboxResponse = (existingResponse, value, checked) => {
    let responseArray = existingResponse ? existingResponse.split(",") : [];
    if (checked) {
      if (!responseArray.includes(value)) {
        responseArray.push(value);
      }
    } else {
      responseArray = responseArray.filter((item) => item !== value);
    }
    return responseArray.join(",");
  };

  const handleSaveAll = (e) => {
    e.preventDefault();

    if (filters.selectedStudent.length < 1) {
      handleToastCenterTop("학생을 선택하세요.");
      setValidateInput((prev) => ({ ...prev, user: true }));
      setTimeout(() => {
        setValidateInput((prev) => ({ ...prev, user: false }));
      }, 3000);

      return;
    }

    if (!termId) {
      handleToastCenterTop("학기를 선택하세요.");
      setValidateInput((prev) => ({ ...prev, term: true }));
      setTimeout(() => {
        setValidateInput((prev) => ({ ...prev, term: false }));
      }, 3000);
      return;
    }

    const trimmedDataToSave = rows.map((item) => {
      const trimmedResponse =
        typeof item.response === "string"
          ? item.response.trim()
          : item.response;
      return { ...item, response: trimmedResponse };
    });

    const modifiedData = trimmedDataToSave.filter((r) => r.isModified === true);
    if (modifiedData.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }

    // 변경된 데이터 세트 처리 로직...
    setIsLoadingModal(true);
    createOrUpdateFormResponsesBulk.mutate(modifiedData, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        handleToastCenterTop("성공적으로 저장되었습니다.");
        setDynamicState((prev) => ({ ...prev, selfReflectionBtn: true }));
        setTimeout(() => {
          setDynamicState((prev) => ({ ...prev, selfReflectionBtn: false }));
        }, 3000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setRows((currentRows) =>
          currentRows.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <FiltersBar
                settingData={newSetting}
                handleDropdownChange={handleDropdownChange}
                filters={filters}
                studentList={studentList}
                validateInput={validateInput}
                termPeriodData={termPeriodData}
                termId={termId}
                currentPeriod={currentPeriod}
                showUsername={false}
                isTitle={true}
              />
            </div>
            <div
              id="selfReflectionPrintArea"
              className="d-flex justify-content-center print-page"
            >
              <table className="" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th colSpan="3"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="1"></td>
                    <td colSpan="1" className="p-2">
                      <form onSubmit={handleSaveAll}>
                        {componentsToFormsData &&
                          componentsToFormsData.map((item, index) => {
                            const thisRow = rows.find((row) => {
                              return (
                                row?.components_to_forms_id?.toString() ===
                                  item?.id?.toString() &&
                                row?.session_id?.toString() ===
                                  sessionId?.toString() &&
                                row?.school_year_id?.toString() ===
                                  filters?.schoolYearId?.toString() &&
                                row?.school_term_id?.toString() ===
                                  termId?.toString()
                              );
                            });
                            return (
                              // <div key={`${item.id}-${item.order}-${index}`}>
                              <DynamicComponentsRenderer
                                components={item.components}
                                customizedContents={item.customized_contents}
                                keyData={keyData}
                                setIsLoadingModal={setIsLoadingModal}
                                key={`${item.id}-${item.order}-${index}`}
                                handleChangeToSave={handleChangeToSave}
                                row={thisRow || {}}
                                componentsToFormId={item.id}
                                dynamicState={dynamicState}
                              />
                              // </div>
                            );
                          })}
                      </form>
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
