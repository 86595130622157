import { useMutation, useQueryClient } from "@tanstack/react-query";
import LessonStudentsService from "../service/lessonStudents";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useLessonStudents(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const lessonStudentsService = new LessonStudentsService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 lessonStudents join해서 가져온다.
  // const lessonStudentsQuery = useQuery(
  //   ["lessonStudents"],
  //   () => lessonStudentsService.findAllLessonStudents(conditions),
  //   {
  //     // enabled: !conditions?.type,
  //     staleTime: 1000 * 60 * 10, //10분,
  //     // onSuccess: (data) => {
  //     //   console.log("lessonStudents Query Data:", data);
  //     // },
  //   }
  // );

  const getLessonStudentsByLessonId = useMutation((forWhere) =>
    lessonStudentsService.getLessonStudentsByLessonId(forWhere)
  );

  const getLessonStudentsByStudentId = useMutation((forWhere) =>
    lessonStudentsService.getLessonStudentsByStudentId(forWhere)
  );

  const getAllLessonStudentsByStudentId = useMutation((forWhere) =>
    lessonStudentsService.getAllLessonStudentsByStudentId(forWhere)
  );

  const createLessonStudents = useMutation(
    (data) => lessonStudentsService.createLessonStudents(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["lessonStudents"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const createLessonStudentsBulk = useMutation(
    (data) => lessonStudentsService.createLessonStudentsBulk(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["lessonStudents"]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const updateLessonStudents = useMutation(
    (data) => {
      // console.log("useLessonStduent", data);
      return lessonStudentsService.updateLessonStudents(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lessonStudents"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating lessonStudents:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const updateLessonStudentsBulk = useMutation(
    (dataArray) => lessonStudentsService.updateLessonStudentsBulk(dataArray),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lessonStudents"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating lessonStudents:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteLessonStudents = useMutation(
    (id) => lessonStudentsService.deleteLessonStudents(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["lessonStudents"]);
      },
    }
  );

  return {
    // lessonStudentsQuery,
    getLessonStudentsByLessonId,
    getLessonStudentsByStudentId,
    getAllLessonStudentsByStudentId,
    createLessonStudents,
    createLessonStudentsBulk,
    updateLessonStudents,
    updateLessonStudentsBulk,
    deleteLessonStudents,
  };
}
