import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import DocumentFormsService from "../service/documentForms";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useDocumentForms(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const documentFormsService = new DocumentFormsService(httpClient);
  // console.log("useDocumentForms conditions", conditions);
  // const documentFormsQuery = useQuery(
  //   ["documentForms", conditions],
  //   () => documentFormsService.findAllDocumentForms(conditions),
  //   {
  //     enabled: !!conditions?.schoolid && !!conditions?.document_type && !!conditions?.document_category && !!conditions?.document_name,
  //     onSuccess: (data) => {
  //       console.log("documentForms Query Data:", data);
  //     },
  //   }
  // );

  const getDocumentForms = useMutation((forWhere) =>
    documentFormsService.getDocumentForms(forWhere)
  );

  const createOrUpdateDocumentForms = useMutation(
    (data) => documentFormsService.createOrUpdateDocumentForms(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["documentForms", conditions]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const createOrUpdateDocumentFormsBulk = useMutation(
    (data) => documentFormsService.createOrUpdateDocumentFormsBulk(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["documentForms", conditions]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const deleteDocumentForms = useMutation(
    (data) => documentFormsService.deleteDocumentForms(data),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["documentForms", conditions]);
      },
    }
  );

  const deleteVariousStudentRecordOne = useMutation(
    (id) => documentFormsService.deleteVariousStudentRecordOne(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["documentForms", conditions]);
      },
    }
  );

  return {
    getDocumentForms,
    createOrUpdateDocumentForms,
    createOrUpdateDocumentFormsBulk,
    deleteDocumentForms,
    deleteVariousStudentRecordOne,
  };
}
