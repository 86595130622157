import React, { useState } from "react";
import { MdEditNote, MdOutlineMessage } from "react-icons/md";
import useAttendance from "../../../hooks/useAttendance";
import moment from "moment";
import { useAuthContext } from "../../../context/AuthContext";
import { checkHrteachers } from "../../../util/checkPermissions";
import { useUIContext } from "../../../context/UIContext";

function AttendanceTable({
  rows,
  dates,
  idsForKey,
  attendanceRecord = [],
  setIsUpLoading,
  schoolId,
  refetchData,
  setAttendanceRecord,
  saveAttendance,
  setSaveAttendance,
  hrTeachersData,
}) {
  const [isSaving, setIsSaving] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const { createAttendance, updateAttendance } = useAttendance();

  const { handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  const DayAndDate = ({ day, number, date, onAllPresent }) => {
    let dayNum;
    if (number === 6) {
      dayNum = 0;
    } else {
      dayNum = number + 1;
    }

    return (
      <th>
        <button
          className="btn btn-outline-primary btn-sm"
          id={date}
          name={dayNum}
          onClick={handleAllPresent}
        >
          전체출석
        </button>
        <br />
        {day}
        <br />
        <span>{date && date.slice(-5)}</span>
        <br />
        <button
          className="btn btn-primary btn-sm"
          name={dayNum}
          onClick={handleClick}
        >
          {isSaving[dayNum] ? "완료" : "저장"}
        </button>
      </th>
    );
  };

  const handleAllPresent = (e) => {
    const dayNum = e.target.name;
    const dateToSave = e.target.id;
    // 기존 자료에서 일치하는 것이 있으면 업데이트 하고, 없으면
    const newRecords = rows.map((student) => {
      const filtered = attendanceRecord.find((r) => {
        const date = new Date(r.date).getDay();
        return (
          r.username === student.username &&
          date.toString() === dayNum.toString()
        );
      });
      return {
        id: filtered ? filtered.id : "",
        username: student.username,
        date: moment(dateToSave).format("YYYY-MM-DD"),
        attendance_status: "출석",
        schoolid: schoolId,
      };
    });

    setAttendanceRecord((prevData) => {
      const updatedData = [...prevData];

      newRecords.forEach((newRecord) => {
        // console.log("newRecord", newRecord);
        const index = updatedData.findIndex((record) => {
          const recordDate = moment(record.date).format("YYYY-MM-DD");
          return (
            record.username === newRecord.username &&
            recordDate === newRecord.date
          );
        });

        if (index !== -1) {
          updatedData[index] = newRecord;
        } else {
          updatedData.push(newRecord);
        }
      });

      return updatedData;
    });

    setSaveAttendance((prevData) => {
      // day에 해당하는 데이터 배열 가져오기
      const dayData = prevData[dayNum] || [];

      const updatedDayData = newRecords.map((newRecord) => {
        // username이 일치하는 객체가 있는지 확인하고, 있다면 업데이트하기
        const index = dayData.findIndex(
          (item) => item.username === newRecord.username
        );
        if (index !== -1) {
          // username이 일치하는 객체가 있다면, newRecord로 업데이트하기
          return newRecord;
        } else {
          // username이 일치하는 객체가 없다면, newRecord 그대로 반환하기
          return newRecord;
        }
      });

      return {
        ...prevData,
        [dayNum]: updatedDayData,
      };
    });

    // console.log("saveAttendance all", saveAttendance);
  };

  const handleChange = (e, username, day) => {
    const id = e.target.id;
    const status = e.target.value;
    const date = e.target.name;

    const newData = {
      schoolid: schoolId,
      username,
      date: moment(date).format("YYYY-MM-DD"),
      attendance_status: status,
      id: Number(id),
    };

    setAttendanceRecord((prevData) => {
      // 'username'과 'date'가 일치하는 레코드를 찾습니다.
      const index = prevData.findIndex((record) => {
        const recordDate = new Date(record.date).toLocaleDateString("sv-SE");
        return record.username === username && recordDate === date;
      });

      if (index !== -1) {
        // 레코드가 존재하면 그 레코드를 업데이트합니다.
        // 단, id는 유지하고, 다른 필드만 업데이트합니다.
        return prevData.map((record, i) =>
          i === index ? { ...record, ...newData, id: record.id } : record
        );
      } else {
        // 레코드가 존재하지 않으면 새 레코드를 추가합니다.
        return [...prevData, newData];
      }
    });

    // console.log("AttendanceRecord", attendanceRecord);

    setSaveAttendance((prevData) => {
      // day에 해당하는 데이터 배열 가져오기
      const dayData = prevData[day] || [];

      // username이 일치하는 객체가 있는지 확인하고, 있다면 업데이트하기
      const updatedDayData = dayData.map((item) =>
        item.username === username ? newData : item
      );

      // username이 일치하는 객체가 없다면, newData 추가하기
      if (!updatedDayData.find((item) => item.username === username)) {
        updatedDayData.push(newData);
      }

      return {
        ...prevData,
        [day]: updatedDayData,
      };
    });

    // console.log("saveAttendance change", saveAttendance);
  };

  const handleClick = (e) => {
    const dayNum = e.target.name;
    // console.log("saveAttendance[dayNum]", saveAttendance[dayNum]);
    if (saveAttendance[dayNum] === undefined) {
      handleToastCenterTop("변동된 데이터가 없습니다.");
      return;
    }

    if (
      rows &&
      rows.length > 0 &&
      !hasPermission(
        null,
        "can_create",
        checkHrteachers(
          hrTeachersData,
          saveAttendance[dayNum][0].username,
          rows[0].school_year
        )
      )
    ) {
      handleToastCenterTop(
        "학생의 담임과 권한이 있는 사람만 저장할 수 있습니다."
      );
      return;
    }

    createAttendance.mutate(saveAttendance[dayNum], {
      onSuccess: () => {
        refetchData();
        setIsUpLoading(false);
        setIsSaving((prev) => {
          const copy = [...prev];
          copy[Number(dayNum)] = true;
          return copy;
        });
        setTimeout(() => {
          setIsSaving((prev) => {
            const copy = [...prev];
            copy[Number(dayNum)] = false;
            return copy;
          });
        }, 2000);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error
        );
      },
    });
  };

  const handleMemo = (id, memo, previousMemo) => {
    const newData = {
      memo,
      id: Number(id),
    };

    // console.log("newData", newData);
    if (memo !== null) {
      updateAttendance.mutate(newData, {
        onSuccess: () => {
          refetchData();
          setIsUpLoading(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error
          );
        },
      });
    }
  };

  return (
    <table className="table border text-center" style={{ minWidth: "700px" }}>
      <thead>
        <tr>
          {idsForKey &&
            Object.keys(idsForKey).map((item) => {
              if (item === "0") return null;
              return <th key={item}>{idsForKey[item][0]}</th>;
            })}
          <th>이름</th>
          {["월", "화", "수", "목", "금", "토", "일"].map((day, i) => (
            <DayAndDate
              key={i}
              day={day}
              number={i}
              date={dates[i]}
              onAllPresent={() => handleAllPresent(i)}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows &&
          rows.map((student, index) => (
            <tr key={index}>
              {Object.keys(idsForKey).map((item, itemIndex) => {
                if (item === "0") return null;
                return <td key={`${index}-${itemIndex}`}>{student[item]}</td>;
              })}
              <td className="align-middle">{student.fullname}</td>
              {[1, 2, 3, 4, 5, 6, 0].map((day, i) => {
                const filtered = attendanceRecord.find((r) => {
                  const date = new Date(r.date).getDay();
                  return r.username === student.username && date === day;
                });
                return (
                  <td className="text-end" key={i}>
                    <div className="d-flex justify-content-center">
                      <div>
                        <select
                          key={filtered ? filtered.id : ""}
                          value={filtered ? filtered.attendance_status : ""}
                          className="form-control m-0 p-0"
                          style={
                            filtered?.attendance_status !== "출석" &&
                            filtered?.attendance_status !== "" &&
                            filtered?.attendance_status !== undefined
                              ? { backgroundColor: "#FFF8E1" }
                              : {}
                          }
                          name={dates[i]}
                          id={filtered ? filtered.id : ""}
                          onChange={(e) =>
                            handleChange(e, student.username, day)
                          }
                        >
                          <option value="">선택</option>
                          <option value="출석">출석</option>
                          <option value="질결">질결</option>
                          <option value="무결">무결</option>
                          <option value="기결">기결</option>
                          <option value="질지">질지</option>
                          <option value="무지">무지</option>
                          <option value="기지">기지</option>
                          <option value="질조">질조</option>
                          <option value="무조">무조</option>
                          <option value="기조">기조</option>
                          <option value="질결과">질결과</option>
                          <option value="무결과">무결과</option>
                          <option value="기결과">기결과</option>
                          <option value="출인결">출인결</option>
                          <option value="출인지">출인지</option>
                          <option value="출인조">출인조</option>
                          <option value="출인결과">출인결과</option>
                        </select>
                      </div>
                      <div>
                        {filtered?.memo ? (
                          <MdOutlineMessage
                            size={22}
                            className="ms-1"
                            title={
                              filtered && filtered.memo
                                ? filtered.memo
                                : "메모 없음"
                            }
                            onClick={() => {
                              if (filtered && filtered.id) {
                                const previousMemo = filtered.memo
                                  ? filtered.memo
                                  : "";
                                const memo = prompt(
                                  "메모를 입력하세요",
                                  previousMemo
                                );
                                handleMemo(filtered.id, memo, previousMemo);
                              } else {
                                alert(
                                  "출석상태 저장 후 메모 기록할 수 있어요."
                                );
                              }
                            }}
                          />
                        ) : (
                          <MdEditNote
                            className="ms-1"
                            size={22}
                            title={
                              filtered && filtered.memo
                                ? filtered.memo
                                : "메모 없음"
                            }
                            onClick={() => {
                              if (filtered && filtered.id) {
                                const previousMemo = filtered.memo
                                  ? filtered.memo
                                  : "";
                                const memo = prompt(
                                  "메모를 입력하세요",
                                  previousMemo
                                );
                                handleMemo(filtered.id, memo, previousMemo);
                              } else {
                                alert(
                                  "출석상태 저장 후 메모 기록할 수 있어요."
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default AttendanceTable;

/* 
                자... 이게 create과 save만 하면 된다. 생각해 보자.
                필요한 정보는 schoolid, username, date, attendance_status
                근데 메모는 따로 저장하니까 별도로 하고
                메모를 먼저 저장해 버릴 수도 있겠군... 메모를 저장하든, status를 저장하든 먼저 저장된 것이 있으면 update를 해야한다.
               그리고 일 단위로 저장한다.
               그렇다면... 저장을 누르면, 해당 일의 기록을 싹 가져와서 저장해야겠군.
               상태를 변경하거나 메모를 쓰면 아래와 같은 구조로 요일별로 저장하고, 저장 누를 때
               해당 요일의 해당하는 번호를 찾아서 그 번호에 있는 [{}]를 저장으로 보내주면 된다.
               ㅇㅋ. 해보자! 밥먹고!
{
  "0": [
    {
      username: "",
      date: "",
      attendance_status: ""
    },
    // ...
  ],
  "1": [
    {
      username: "",
      date: "",
      attendance_status: ""
    },
    // ...
  ]
}
                */
