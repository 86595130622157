import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";
import FormResponsesService from "../service/formResponses";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useFormResponses(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const formResponsesService = new FormResponsesService(httpClient);
  // const formResponsesQuery = useQuery(
  //   ["formResponses", conditions],
  //   () => formResponsesService.findAllFormResponses(conditions),
  //   {
  //     enabled:
  //       !!conditions?.schoolid &&
  //       !!conditions?.school_year_id &&
  //       !!conditions?.record_list_id,
  //     staleTime: 1000 * 60 * 1, //1분,
  //     // onSuccess: (data) => {
  //     //   console.log("formResponses Query Data:", data);
  //     // },
  //   }
  // );

  const getFormResponsesByStudent = useMutation((forWhere) =>
    formResponsesService.getFormResponsesByStudent(forWhere)
  );

  const createOrUpdateFormResponsesBulk = useMutation(
    (data) => formResponsesService.createOrUpdateFormResponsesBulk(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["formResponses", conditions]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  return {
    getFormResponsesByStudent,
    createOrUpdateFormResponsesBulk,
  };
}
