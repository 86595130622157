import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import StudentInfoService from "../service/studentInfo";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useStudentInfo(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const studentInfoService = new StudentInfoService(httpClient);

  //users db에서 studentinfo join해서 가져온다.
  const studentInfoQuery = useQuery(
    ["studentInfo", conditions || ""],
    () =>
      studentInfoService.findAllStudentInfoWithRegiInfoBySchoolid(conditions),
    {
      // onSuccess: (success) => {
      //   console.log("success", success);
      // },
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 2, //2분,
    }
  );

  const findStudentInfo = useMutation(
    (studentInfo) => studentInfoService.findStudentInfo(studentInfo),
    {
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error studentInfo 가져오는 중:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const updateStudentInfo = useMutation(
    (studentInfo) => studentInfoService.updateStudentInfo(studentInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["studentInfo"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating studentInfo:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const createStudentInfo = useMutation(
    (studentInfo) => studentInfoService.createStudentInfo(studentInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["studentInfo"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  return {
    studentInfoQuery,
    updateStudentInfo,
    createStudentInfo,
    findStudentInfo,
  };
}
