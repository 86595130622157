import React, { useState } from "react";
import {
  averageAllNumbersOfObject,
  sumAllNumbersOfObject,
} from "../../../../util/dealingArrObj";
import { useUIContext } from "../../../../context/UIContext";
import PromptModal from "../../../../unicomponents/PromptModal";
import { useAuthContext } from "../../../../context/AuthContext";

export default function LessonStudentsItemsTable({
  lessonStudentsData,
  setLessonStudentsData,
  evaluationType,
  lessonSetting,
  updateLessonStudents,
  updateLessonStudentsBulk,
  deleteLessonStudents,
  lessonInfo,
  evalItemsData,
}) {
  /*
생각해 보자. level1 행을 찾아서 td 1개 만든다. 
그 level1을 parent_id를 갖고 있는 level2를 찾아서 그 옆에 td를 1번 추가한다. 없으면 빈 td 2개 추가한다.
 parent_id가 같은 level2가 또 있으면 tr를 1개 추가한다. 앞에 td는 빈칸 
추가된 level2를 parent_id를 갖고 있는 level3를 찾아서 그 옆에 td를 추가한다. 없으면 빈 td 1개 추가한다.
 parent_id가 같은 level3가 또 있으면 tr를 1개 추가한다. 앞에 td 2개는 빈칸 

좋아 됐다.

이제 해야 할 것은..
1. 저장된 성적이 표시되도록
2. 정보 입력 하는 것에 따라서 학생별로 state에 기록되도록
3. 저장버튼 누르면 해당 학생의 성적이 create (저장) 또는 업데이트 되도록
4. 모두 저장 누르면 수정 경험이 있는 애들 것 모두 저장 되도록...
5. 기타 스타일 좀 손 보고.... input, select 등 eval type에 따라서 다르게 나오는 것 하면 되겠군

 */
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataForPromptModal, setDataForPromptModal] = useState({});
  const [confirmDelete, setConfirmDelete] = useState([]);

  const { user, hasPermission } = useAuthContext();

  const initiatePromptModal = (data) => {
    setModalOpen(true);
    setDataForPromptModal(data);
  };

  const handlePromptModalSubmit = (dataForPromptModal) => {
    const { id, ...updatedData } = dataForPromptModal;

    setLessonStudentsData((currentDataSets) =>
      currentDataSets.map((dataSet) =>
        dataSet.id === id
          ? { ...dataSet, ...updatedData, isModified: true }
          : dataSet
      )
    );
  };

  const closeModal = () => setModalOpen(false);

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTdChange = (username, evalItemId, event) => {
    const { value } = event.target;
    const field = event.target.name;
    let updatedRows = [...lessonStudentsData];

    //lessonid와 username으로 찾은 후 eval_items_grades에서 evalItems id를 key 값으로
    if (field === "eval_item_grades") {
      updatedRows = updatedRows.map((row) => {
        if (row.lesson_id === lessonInfo.id && row.username === username) {
          const prev = row.eval_item_grades || {};
          return {
            ...row,
            eval_item_grades: {
              ...prev,
              [evalItemId]: value,
            },
            isModified: true,
          };
        }
        return row;
      });
      setLessonStudentsData(updatedRows);
    }
  };

  // 태그 변경을 처리하는 함수
  const handleTagChange = (tagName, event, username, tagIndex) => {
    const { value } = event.target; // 선택된 새로운 태그 값
    const updatedRows = lessonStudentsData.map((row) => {
      if (row.lesson_id === lessonInfo.id && row.username === username) {
        const updatedTags = row.tags.map((tag, idx) => {
          if (idx === tagIndex) {
            return { [tagName]: value }; // 태그 값을 업데이트
          }
          return tag;
        });

        return { ...row, tags: updatedTags, isModified: true };
      }
      return row;
    });

    // console.log("updatedRows", updatedRows);
    setLessonStudentsData(updatedRows); // 상태 업데이트
  };

  function renderTd(evaluationType, eval_item_grades, evalItemId, username) {
    const uniqueKey = `${username}-${evalItemId}-${evaluationType}`;
    switch (evaluationType) {
      case "100점제":
        return (
          <td key={uniqueKey}>
            <input
              className="input-like-span"
              type="number"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            />
          </td>
        );

      case "이수제":
        return (
          <td key={uniqueKey}>
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option></option>
              <option value="pass">이수</option>
              <option value="fail">미이수</option>
            </select>
          </td>
        );
      case "3단평가":
        return (
          <td key={uniqueKey}>
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value="3">잘함</option>
              <option value="2">보통</option>
              <option value="1">노력요함</option>
              <option value="0">미실시</option>
              <option value=""></option>
            </select>
          </td>
        );

      case "ABC":
        return (
          <td key={uniqueKey}>
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value=""></option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="F">F</option>
            </select>
          </td>
        );
      case "직접입력":
        return (
          <td key={uniqueKey}>
            <input
              className="input-like-span"
              type="text"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            />
          </td>
        );
      case "5단평가":
        return (
          <td key={uniqueKey}>
            <select
              className="select-like-span"
              name="eval_item_grades"
              onChange={(e) => handleTdChange(username, evalItemId, e)}
              value={eval_item_grades?.[evalItemId] ?? ""}
            >
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
              <option value="0">0</option>
              <option value=""></option>
            </select>
          </td>
        );
      default:
        return <td key={uniqueKey}>에러</td>;
    }
  }

  const findItems = (parentId, level) =>
    evalItemsData.filter(
      (item) => item.hierarchy_level === level && item.parent_id === parentId
    );

  const findByLevels = (level) =>
    evalItemsData.filter((item) => item.hierarchy_level === level);

  const checkEvalTypes = (evalTypes) =>
    evalTypes.some((evalType) =>
      evalItemsData.every((item) => item.evaluation_type === evalType)
    );

  const renderTableRows = () => {
    const level1Items = findItems(0, 1);
    return level1Items.flatMap((level1) => {
      const level2Items = findItems(level1.id, 2);
      if (level2Items.length === 0) {
        const rowKey = `level1-${level1.id}`;
        return (
          <tr key={rowKey}>
            <td>{level1.content}</td>
            {findByLevels(2).length > 0 && <td></td>}
            {findByLevels(3).length > 0 && <td></td>}
            {lessonStudentsData?.map((student) => {
              return renderTd(
                level1.evaluation_type,
                student.eval_item_grades,
                level1.id,
                student.username
              );
            })}
          </tr>
        );
      } else {
        return level2Items.flatMap((level2, level2Index) => {
          const level3Items = findItems(level2.id, 3);
          if (level3Items.length === 0) {
            const rowKey = `level2-${level2.id}-${level2Index}`;

            return (
              <tr key={rowKey}>
                <td>{level2Index === 0 ? level1.content : ""}</td>
                <td>{level2.content}</td>
                {findByLevels(3).length > 0 && <td></td>}

                {lessonStudentsData?.map((student) => {
                  return renderTd(
                    level2.evaluation_type,
                    student.eval_item_grades,
                    level2.id,
                    student.username
                  );
                })}
              </tr>
            );
          } else {
            return level3Items.flatMap((level3, level3Index) => {
              const rowKey = `level3-${level3.id}-${level3Index}`;

              return (
                <tr key={rowKey}>
                  <td>
                    {level2Index === 0 && level3Index === 0
                      ? level1.content
                      : ""}
                  </td>
                  <td>{level3Index === 0 ? level2.content : ""}</td>
                  <td>{level3.content}</td>
                  {lessonStudentsData?.map((student) => {
                    return renderTd(
                      level3.evaluation_type,
                      student.eval_item_grades,
                      level3.id,
                      student.username
                    );
                  })}
                </tr>
              );
            });
          }
        });
      }
    });
  };

  //아... 드디어 save 만 하면 되는 것 같다...
  const saveStudentGrade = (student) => {
    // console.log("student", student);

    if (!student.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      // setAlertModalState({
      //   visible: true,
      //   message: "테스트 해보자",
      // });
      return;
    }
    const { id, comment, eval_item_grades, tags } = student;
    const dataSet = { id, comment, eval_item_grades, tags };

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(
        "본인 것 외 수정은 권한이 있는 사람만 수정할 수 있습니다."
      );
      return;
    }

    setIsLoadingModal(true);
    updateLessonStudents.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setLessonStudentsData((currentDataSets) =>
      currentDataSets.map((dataSet) =>
        dataSet.id === id ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const saveAllStudentsGrade = () => {
    const dataArray = lessonStudentsData.filter(
      (data) => data.isModified === true
    );

    if (dataArray.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(
        "본인 것 외 수정은 권한이 있는 사람만 수정할 수 있습니다."
      );
      return;
    }

    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataArray);
    setIsLoadingModal(true);
    updateLessonStudentsBulk.mutate(dataArray, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setLessonStudentsData((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleDelete = (row) => {
    const id = row.id;

    const checkOwner = lessonInfo.teachers.some(
      (teacher) => teacher.username === user.username
    );
    if (!checkOwner && !hasPermission(null, "can_update")) {
      handleToastCenterTop(
        "본인 것 외 수정은 권한이 있는 사람만 수정할 수 있습니다."
      );
      return;
    }

    setIsLoadingModal(true);
    deleteLessonStudents.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        setLessonStudentsData((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  return (
    <div>
      <div className="ms-3 table-responsive" style={{ minWidth: "800px" }}>
        <table
          className="table table-sm table-bordered"
          style={{ fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th className="align-middle" style={{ minWidth: "200px" }}>
                1단계
                <button
                  className="btn btn-sm btn-primary ms-3"
                  onClick={() => saveAllStudentsGrade()}
                >
                  {savedBulk ? "저장완료" : "모두저장"}
                </button>
              </th>
              {findByLevels(2).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}>
                  2단계
                </th>
              )}
              {findByLevels(3).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}>
                  3단계
                </th>
              )}
              {lessonStudentsData.map((student) => (
                <th
                  key={`student-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    fontSize: "11px",
                  }}
                >
                  <button
                    className="btn btn-sm btn-primary ps-2 pe-2 p-0 m-0"
                    style={{ fontSize: "11px" }}
                    onClick={() => saveStudentGrade(student)}
                  >
                    {savedRows.includes(student.id) ? "완료" : "저장"}
                  </button>
                  <br />
                  {student.fullname}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
          <tfoot>
            {checkEvalTypes(["100점제"]) && (
              <tr>
                <th className="align-middle" style={{ minWidth: "200px" }}>
                  합계
                </th>
                {findByLevels(2).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {findByLevels(3).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ minWidth: "200px" }}
                  ></th>
                )}

                {lessonStudentsData.map((student) => (
                  <th
                    key={`sum-${student.id}`}
                    className="text-center"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {student.eval_item_grades
                      ? sumAllNumbersOfObject(student.eval_item_grades)
                      : ""}
                  </th>
                ))}
              </tr>
            )}

            {checkEvalTypes(["100점제", "3단평가", "5단평가"]) && (
              <tr>
                <th className="align-middle" style={{ minWidth: "200px" }}>
                  평균
                </th>
                {findByLevels(2).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {findByLevels(3).length > 0 && (
                  <th
                    className="align-middle"
                    style={{ minWidth: "200px" }}
                  ></th>
                )}

                {lessonStudentsData.map((student) => (
                  <th
                    key={`avg-${student.id}`}
                    className="text-center"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {student.eval_item_grades
                      ? typeof averageAllNumbersOfObject(
                          student.eval_item_grades
                        ) === "number"
                        ? averageAllNumbersOfObject(
                            student.eval_item_grades
                          ).toFixed(1)
                        : ""
                      : ""}
                  </th>
                ))}
              </tr>
            )}
            <tr>
              <th className="align-middle" style={{ minWidth: "200px" }}>
                세부특기사항
              </th>
              {findByLevels(2).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}
              {findByLevels(3).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}

              {lessonStudentsData.map((student) => (
                <th
                  key={`comment-${student.id}`}
                  className="text-center"
                  style={{
                    fontSize: "11px",
                    minWidth: "50px",
                  }}
                >
                  <button
                    className="btn btn-sm btn-outline-secondary ps-1  p-0 m-0"
                    style={{
                      fontSize: "11px",
                      minWidth: "50px",
                      maxWidth: "60px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() =>
                      initiatePromptModal({
                        id: student.id,
                        comment: student.comment,
                      })
                    }
                  >
                    {student.comment ? student.comment : "빈칸"}
                  </button>
                </th>
              ))}
            </tr>

            <tr>
              <th className="align-middle" style={{ minWidth: "200px" }}>
                태그
              </th>
              {findByLevels(2).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}
              {findByLevels(3).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}
              {lessonStudentsData.map((student) => (
                <th
                  key={`tag-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    fontSize: "11px",
                  }}
                >
                  <div style={{ fontSize: "11px" }}>
                    <div>
                      {student?.tags?.map((tag, tagIndex) => {
                        const tagName = Object.keys(tag)[0];
                        return (
                          <div key={tagIndex}>
                            <div
                              className="text-center"
                              style={{ marginBottom: "1px" }}
                            >
                              {tagName}
                            </div>
                            <select
                              className="form-control p-0"
                              style={{ fontSize: "11px" }}
                              value={tag[tagName]}
                              name="tags"
                              onChange={(e) =>
                                handleTagChange(
                                  tagName,
                                  e,
                                  student.username,
                                  tagIndex
                                )
                              }
                            >
                              <option value="1">매우약함</option>
                              <option value="2">약함</option>
                              <option value="3">보통</option>
                              <option value="4">강함</option>
                              <option value="5">매우강함</option>
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th>삭제버튼</th>
              {findByLevels(2).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}
              {findByLevels(3).length > 0 && (
                <th className="align-middle" style={{ minWidth: "200px" }}></th>
              )}
              {lessonStudentsData.map((student) => (
                <td
                  key={`delete-${student.id}`}
                  className="text-center"
                  style={{
                    minWidth: "50px",
                    fontSize: "11px",
                  }}
                >
                  {confirmDelete.includes(student.id) ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleDelete(student)}
                        className="btn btn-sm btn-danger ps-2 pe-2 p-0 m-0"
                        style={{ fontSize: "11px" }}
                      >
                        확인
                      </button>
                      <button
                        type="button"
                        onClick={() => handleCancelDelete()}
                        className="btn btn-sm btn-secondary ps-2 pe-2 p-0 m-0"
                        style={{ fontSize: "11px" }}
                      >
                        취소
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleConfirmDelete(student)}
                      className="btn btn-sm btn-warning ps-2 pe-2 p-0 m-0"
                      style={{ fontSize: "11px" }}
                    >
                      삭제
                    </button>
                  )}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
        <PromptModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialState={dataForPromptModal}
          handleSubmit={handlePromptModalSubmit}
          keyName="comment"
          title="세부특기사항 입력"
          textCount={true}
          closeOnOutsideClick={false}
        />
      </div>
    </div>
  );
}
