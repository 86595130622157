import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GroupsMembersService from "../service/groupsMembers.js";
import HttpClient from "../network/http.js";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext.jsx";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useGroupsMembers(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const groupsMembersService = new GroupsMembersService(httpClient);

  const groupsMembersQuery = useQuery(
    ["groupsMembers", conditions],
    () => groupsMembersService.findAllGroupsMembers(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.groups_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("GroupsMembers Query Data:", data);
      // },
    }
  );

  const groupsMembersAllQuery = useQuery(
    ["groupsMembersAll", conditions],
    () => groupsMembersService.findGroupsMembersAll(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid && conditions?.key === "all",
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("GroupsMembersAll Query Data:", data);
      // },
    }
  );

  const createGroupsMembers = useMutation(
    (data) => groupsMembersService.createGroupsMembers(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["groupsMembers"]),
      onError: (error) => {
        console.error("Error creatingGroupsMembers:", error);
      },
    }
  );

  const createGroupsMembersBulk = useMutation(
    (array) => groupsMembersService.createGroupsMembersBulk(array),
    {
      onSuccess: () => queryClient.invalidateQueries(["groupsMembers"]),
      onError: (error) => {
        console.error("Error creatingGroupsMembersBulk:", error);
      },
    }
  );

  const updateGroupsMembers = useMutation(
    (data) => groupsMembersService.updateGroupsMembers(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["groupsMembers"]),
      onError: (error) => {
        console.error("Error updatingGroupsMembers:", error);
      },
    }
  );

  const deleteGroupsMembers = useMutation(
    (id) => groupsMembersService.deleteGroupsMembers(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["groupsMembers"]);
      },
    }
  );

  return {
    groupsMembersQuery,
    createGroupsMembers,
    createGroupsMembersBulk,
    updateGroupsMembers,
    deleteGroupsMembers,
    groupsMembersAllQuery,
  };
}
