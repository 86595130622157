import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TermPeriodService from "../service/termPeriod";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useTermPeriod(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const termPeriodService = new TermPeriodService(httpClient);
  // console.log("conditions", conditions);
  //users db에서 termPeriod join해서 가져온다.
  const termPeriodQuery = useQuery(
    ["termPeriod"],
    () => termPeriodService.findAllTermPeriod(conditions),
    {
      // enabled: !conditions?.type,
      staleTime: 1000 * 60 * 5, //5분,
      // onSuccess: (data) => {
      //   console.log("termPeriod Query Data:", data);
      // },
    }
  );

  const createTermPeriod = useMutation(
    (data) => termPeriodService.createTermPeriod(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["termPeriod"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateTermPeriod = useMutation(
    (data) => termPeriodService.updateTermPeriod(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["termPeriod"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating termPeriod:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteTermPeriod = useMutation(
    (id) => termPeriodService.deleteTermPeriod(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["termPeriod"]);
      },
    }
  );

  return {
    termPeriodQuery,
    createTermPeriod,
    updateTermPeriod,
    deleteTermPeriod,
  };
}
