import { useQuery } from "@tanstack/react-query";
import AllFormsService from "../service/allForms";
import HttpClient from "../network/http";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useAllForms(conditions) {
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const allFormsService = new AllFormsService(httpClient);

  // const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 allForms join해서 가져온다.

  const queryKey = [
    "allForms",
    conditions?.schoolid,
    conditions?.form_category,
  ];

  const allFormsQuery = useQuery(
    queryKey,
    () => allFormsService.findAllAllForms(conditions),
    {
      enabled: !!conditions?.form_category,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("allForms Query Data:", data);
      // },
    }
  );

  // const createAllForms = useMutation(
  //   (data) => allFormsService.createAllForms(data),
  //   {
  //     onSuccess: () => queryClient.invalidateQueries(["allForms"]),
  //     onError: (error) => {
  //       console.error("Error creating:", error);
  //     },
  //   }
  // );

  // const updateAllForms = useMutation(
  //   (data) => allFormsService.updateAllForms(data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["allForms"]);
  //     },
  //     onError: (error) => {
  //       // Handle the failure by displaying an error message or performing other actions
  //       console.error("Error updating allForms:", error);
  //       alert(error);
  //       // Add your error handling logic here
  //     },
  //   }
  // );

  // const deleteAllForms = useMutation(
  //   (data) => allFormsService.deleteAllForms(data),
  //   {
  //     onSuccess: () => {
  //       // Refetch the query first
  //       queryClient.invalidateQueries(["allForms"]);
  //     },
  //   }
  // );

  return {
    allFormsQuery,
    // createAllForms,
    // updateAllForms,
    // deleteAllForms,
  };
}
