import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApprovalGroupMembersService from "../service/approvalGroupMembers.js";
import HttpClient from "../network/http.js";
import { fetchCsrfToken, useAuthContext } from "../context/AuthContext.jsx";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useApprovalGroupMembers(conditions) {
  const queryClient = useQueryClient();
  const { address } = useAuthContext();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken(), address);
  const approvalGroupMembersService = new ApprovalGroupMembersService(
    httpClient
  );

  const approvalGroupMembersQuery = useQuery(
    ["approvalGroupMembers", conditions],
    () => approvalGroupMembersService.findAllApprovalGroupMembers(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("ApprovalGroupMembers Query Data:", data);
      // },
    }
  );

  const createApprovalGroupMembers = useMutation(
    (data) => approvalGroupMembersService.createApprovalGroupMembers(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["approvalGroupMembers"]),
      onError: (error) => {
        console.error("Error creatingApprovalGroupMembers:", error);
      },
    }
  );

  const updateApprovalGroupMembers = useMutation(
    (data) => approvalGroupMembersService.updateApprovalGroupMembers(data),
    {
      onSuccess: () => queryClient.invalidateQueries(["approvalGroupMembers"]),
      onError: (error) => {
        console.error("Error updatingApprovalGroupMembers:", error);
      },
    }
  );

  const deleteApprovalGroupMembers = useMutation(
    (id) => approvalGroupMembersService.deleteApprovalGroupMembers(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["approvalGroupMembers"]);
      },
    }
  );

  return {
    approvalGroupMembersQuery,
    createApprovalGroupMembers,
    updateApprovalGroupMembers,
    deleteApprovalGroupMembers,
  };
}
