import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../../../context/AuthContext";

export default function CareerTable({ rows, setRows, tagList, activeMenu }) {
  //submit 누르면 로딩 뜨도록
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  const optionEndDate =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.end_date || "";

  //submit를 위한
  const { updateEducationalActivities, deleteEducationalActivities } =
    useEducationalActivities();

  const queryClient = useQueryClient();

  const handleTagChange = (index, event) => {
    const newTagKey = event.target.value; // 사용자가 선택한 태그 이름
    const newTag = { [newTagKey]: "3" }; // 새 태그 객체 생성

    setRows((currentRows) =>
      currentRows.map((row, rowIndex) => {
        if (rowIndex === index) {
          // 현재 태그 배열에서 선택한 태그의 키가 이미 존재하는지 확인
          const isTagAlreadyAdded = row.tags.some((tagObj) =>
            tagObj.hasOwnProperty(newTagKey)
          );

          // 태그가 이미 존재하지 않는 경우에만 추가
          return isTagAlreadyAdded
            ? row // 태그가 이미 있다면 현재 상태 유지
            : {
                ...row,
                selectedTag: newTagKey, // 객체 대신 태그 이름(키)를 저장
                tags: [...row.tags, newTag], // 새 태그 객체 추가
                isModified: true,
              };
        } else {
          return row; // 다른 행은 변경하지 않음
        }
      })
    );
  };

  const handleTrChange = (index, event) => {
    // console.log("tr rows", rows);

    const field = event.target.name;
    // 태그 필드에 대한 처리
    // extra_attributes 또는 기타 필드에 대한 처리
    const value = field.includes("extra_attributes")
      ? {
          ...rows[index].extra_attributes,
          [event.target.id]: event.target.value,
        }
      : event.target.value;

    setRows((currentRows) =>
      currentRows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value, isModified: true } : row
      )
    );
  };

  const handleSubmit = (index) => {
    const dataSet = rows[index];

    // console.log("dataSet", dataSet);
    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      return;
    }

    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataSet);

    if (!hasPermission(dataSet?.creator_username, "can_update")) {
      handleToastCenterTop(
        "본인 것 외 수정은 권한이 있는 사람만 수정할 수 있습니다."
      );
      return;
    }

    setIsLoadingModal(true);
    updateEducationalActivities.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });

    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleClick = (index, tagToRemove) => {
    setRows((currentRows) =>
      currentRows.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              // `tagToRemove` 키를 가지지 않은 객체만 필터링하여 태그 배열을 업데이트
              tags: row.tags.filter(
                (tagObj) => !tagObj.hasOwnProperty(tagToRemove)
              ),
              isModified: true,
            }
          : row
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    if (
      window.confirm(
        "정말 삭제하시겠습니까? 이 활동에 속한 모든 학생기록도 삭제됩니다."
      )
    ) {
      const id = row.id;

      if (!hasPermission(row?.creator_username, "can_delete")) {
        handleToastCenterTop(
          "본인 것 외 삭제는 권한이 있는 사람만 삭제할 수 있습니다."
        );
        return;
      }

      setIsLoadingModal(true);
      deleteEducationalActivities.mutate(id, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          setConfirmDelete([]);
          queryClient.invalidateQueries(["educationalActivities"]);
          setRows((rows) => rows.filter((row) => row.id !== id));
        },
        onError: (error) => {
          setIsLoadingModal(false);
          setConfirmDelete([]);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    } else {
      setConfirmDelete([]);
      console.log("취소되었습니다.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="container-fluid">
        <table
          className="table table-bordered"
          style={
            activeMenu?.options?.length > 0 &&
            activeMenu?.options[0]?.[activeMenu.schoolid]
              ? { fontSize: "smaller" }
              : {}
          }
        >
          <thead>
            <tr className="table-secondary">
              <th width={50}>과정</th>
              <th style={{ minWidth: "80px", width: "200px" }}>활동명</th>
              <th style={{ width: "100px" }}>시작날짜</th>
              {optionEndDate && <th style={{ width: "100px" }}>종료날짜</th>}
              <th style={{ minWidth: "60px", width: "65px" }}>시수</th>
              <th style={{ minWidth: "150px" }}>특기사항</th>
              <th style={{ width: "80px" }}>개설자</th>
              <th style={{ minWidth: "50px", width: "85px" }}>태그선택</th>
              <th style={{ width: "120px" }}>태그</th>
              <th style={{ width: "60px" }}></th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => (
              <Trs
                key={row.id}
                row={row}
                index={index}
                handleTrChange={handleTrChange}
                handleSubmit={handleSubmit}
                tagList={tagList}
                handleClick={handleClick}
                handleTagChange={handleTagChange}
                savedRows={savedRows}
                handleDelete={handleDelete}
                confirmDelete={confirmDelete}
                handleConfirmDelete={handleConfirmDelete}
                handleCancelDelete={handleCancelDelete}
                optionEndDate={optionEndDate}
              />
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

function Trs({
  row,
  index,
  handleTrChange,
  handleSubmit,
  tagList,
  handleClick,
  handleTagChange,
  savedRows,
  handleDelete,
  confirmDelete,
  handleConfirmDelete,
  handleCancelDelete,
  optionEndDate,
}) {
  return (
    <React.Fragment>
      <tr style={{ borderBottom: "3px solid #000" }}>
        <td>{row.schoolStage}</td>
        <td className="p-0">
          <textarea
            type="text"
            className="input-like-span"
            name="title"
            value={row.title}
            onChange={(e) => handleTrChange(index, e)}
            rows={3}
          />
        </td>
        <td>
          <input
            type="date"
            className="input-like-span"
            name="start_date"
            value={row.start_date}
            onChange={(e) => handleTrChange(index, e)}
            style={{ width: "105px" }}
          />
        </td>
        {optionEndDate && (
          <td>
            <input
              type="date"
              className="input-like-span"
              name="extra_attributes"
              id="end_date"
              value={row.extra_attributes?.end_date}
              onChange={(e) => handleTrChange(index, e)}
              style={{ width: "105px" }}
            />
          </td>
        )}
        <td>
          <input
            type="number"
            className="input-like-span"
            name="extra_attributes"
            id="hours"
            value={row.extra_attributes?.hours}
            onChange={(e) => handleTrChange(index, e)}
          />
        </td>
        <td className="p-0">
          <textarea
            type="text"
            className="input-like-span"
            name="content"
            value={row.content}
            onChange={(e) => handleTrChange(index, e)}
            rows={3}
          />
        </td>
        <td width={80}>{row.fullname}</td>
        <td>
          <select
            className="select-like-span"
            name="tags"
            value={row.selectedTag} // 각 행의 선택된 태그 값을 반영
            onChange={(e) => handleTagChange(index, e)}
          >
            <option value="">선택</option>
            {tagList &&
              tagList[0]?.tags.map((tag, idx) => (
                <option key={idx} value={tag}>
                  {tag}
                </option>
              ))}
          </select>
        </td>
        <td width={128}>
          {row?.tags?.map((tagObj, tagIndex) => {
            const tagKey = Object.keys(tagObj)[0]; // 객체에서 첫 번째 키를 추출
            return (
              <span key={tagIndex}>
                {tagKey}
                <MdOutlineCancel
                  role="button"
                  onClick={() => {
                    handleClick(index, tagKey); // 태그 삭제 함수는 키를 기준으로 태그를 식별해야 함
                  }}
                />
              </span>
            );
          })}
        </td>
        <td width={60} valign="middle" className="p-1 text-center">
          <button
            type="button"
            onClick={() => handleSubmit(index)}
            className="btn btn-sm p-1 btn-primary"
          >
            {savedRows.includes(row.id) ? "저장완료" : "저장"}
          </button>
          {confirmDelete.includes(row.id) ? (
            <>
              <button
                type="button"
                onClick={() => handleDelete(row)}
                className="btn btn-sm p-1 btn-danger mt-1"
              >
                확인
              </button>
              <button
                type="button"
                onClick={() => handleCancelDelete()}
                className="btn btn-sm p-1 btn-secondary mt-1"
              >
                취소
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() => handleConfirmDelete(row)}
              className="btn btn-sm p-1 btn-warning mt-1"
            >
              삭제
            </button>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}
