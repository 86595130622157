import React, { useEffect, useState } from "react";
import HelpHyperLink from "../../../../unicomponents/HelpHyperLink";
import useUniComponent from "../../../../hooks/useUniComponent";
import useGroupsAuthorities from "../../../../hooks/useGroupsAuthorities";
import styles from "../css/AuthoritySetting.module.css"; // 모듈 CSS 가져오기
import { useUIContext } from "../../../../context/UIContext";

export default function AuthoritySetting({ groupsData, schoolId }) {
  const [selectedGroup, setSelectedGroup] = useState({});

  // 각 행의 체크박스 상태를 관리하는 상태
  const [permissions, setPermissions] = useState([]);
  const [allMenus, setAllMenus] = useState([]);
  const [saved, setSaved] = useState(false);

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const { commonMenus } = useUniComponent();

  const {
    groupsAuthoritiesQuery: { data: groupsAuthoritiesData },
    createOrUpdateGroupsAuthorities,
  } = useGroupsAuthorities({
    schoolid: schoolId,
    groups_id: selectedGroup?.id || "",
  });

  // 초기 데이터 로드
  useEffect(() => {
    if (groupsAuthoritiesData && groupsAuthoritiesData.length > 0) {
      setPermissions(
        groupsAuthoritiesData.map((perm) => ({
          ...perm,
          can_access: perm.can_access || false,
          can_read: perm.can_read || false,
          can_create: perm.can_create || false,
          can_update: perm.can_update || false,
          can_delete: perm.can_delete || false,
          isModified: false,
        }))
      );
    }
  }, [groupsAuthoritiesData]);

  // 초기 데이터 로드
  useEffect(() => {
    if (commonMenus && commonMenus.length > 0) {
      // `categoryorder`로 정렬한 후 `parentcategory`로 정렬
      const sortedMenus = [...commonMenus].sort((a, b) => {
        if (a.parentcategory < b.parentcategory) return -1;
        if (a.parentcategory > b.parentcategory) return 1;
        // `parentcategory`가 같을 경우 `categoryorder`로 정렬
        return a.categoryorder - b.categoryorder;
      });
      setAllMenus(sortedMenus);
    }
  }, [commonMenus]);

  // 전체 체크박스 상태 변경 함수
  const handleAllCheckboxChange = (permissionType, isChecked) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = allMenus.map((menu) => {
        const existingPermission = prevPermissions.find(
          (perm) => perm.menunames_id === menu.id
        );

        if (existingPermission) {
          // 기존 항목이 있을 경우 해당 권한 업데이트
          return {
            ...existingPermission,
            [permissionType]: isChecked,
            isModified: true,
          };
        } else {
          // 기존 항목이 없을 경우 새로 추가
          return {
            menunames_id: menu.id,
            [permissionType]: isChecked,
            can_access: permissionType === "can_access" ? isChecked : false,
            can_read: permissionType === "can_read" ? isChecked : false,
            can_create: permissionType === "can_create" ? isChecked : false,
            can_update: permissionType === "can_update" ? isChecked : false,
            can_delete: permissionType === "can_delete" ? isChecked : false,
            isModified: true,
          };
        }
      });
      return updatedPermissions;
    });

    // 전체 체크박스 상태 관리
    // setAllChecked((prevAllChecked) => ({
    //   ...prevAllChecked,
    //   [permissionType]: isChecked,
    // }));
  };

  // 체크박스 상태 업데이트 및 변경된 항목 표시
  const handleCheckboxChange = (menuId, permissionType) => {
    setPermissions((prevPermissions) => {
      // 해당 menuId에 해당하는 항목이 있는지 찾기
      const existingPermission = prevPermissions.find(
        (perm) => perm.menunames_id === menuId
      );

      if (existingPermission) {
        // 기존 항목이 있을 경우 상태를 업데이트
        return prevPermissions.map((perm) =>
          perm.menunames_id === menuId
            ? {
                ...perm,
                [permissionType]: !perm[permissionType],
                isModified: true,
              }
            : perm
        );
      } else {
        // 기존 항목이 없을 경우 새로운 항목 추가
        return [
          ...prevPermissions,
          {
            menunames_id: menuId,
            [permissionType]: true,
            can_access: false,
            can_read: false,
            can_create: false,
            can_update: false,
            can_delete: false,
            isModified: true,
          },
        ];
      }
    });
    // console.log("permissions", permissions);
  };

  const handleGroupChange = (e) => {
    const groupId = e.target.value;
    const selectedGroup = groupsData.find(
      (group) => group.id.toString() === groupId.toString()
    );
    // console.log("groupId", groupId);
    // console.log("groupsData", groupsData);
    // console.log("Selected Group:", selectedGroup);
    setSelectedGroup(selectedGroup);
    setPermissions([]);
  };

  // 서버에 수정된 데이터를 저장하는 함수
  const handleSavePermissions = async () => {
    if (!selectedGroup) {
      handleToastCenterTop("선택된 그룹이 없습니다.");
      return;
    }

    const isModifiedPermissions = permissions.filter((perm) => perm.isModified);
    const payload = isModifiedPermissions.map((perm) => ({
      ...perm,
      groups_id: selectedGroup.id,
      schoolid: schoolId,
    }));

    if (isModifiedPermissions.length < 1) {
      handleToastCenterTop("변동된 데이터가 없습니다.");
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateGroupsAuthorities.mutate(payload, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setPermissions((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            그룹별 권한 설정{" "}
            <HelpHyperLink link={"https://youtu.be/aHpbtzc_8es"} />
          </h3>
        </div>
        <div className="row mt-2">
          <div className="col">
            <select
              className="form-control"
              onChange={handleGroupChange}
              value={selectedGroup?.id}
            >
              <option>그룹선택</option>
              {groupsData &&
                groupsData.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.group_name} (설명: {group.description})
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="alert alert-info ms-3 p-1 d-flex align-items-center"
            style={{ width: "96%" }}
          >
            <ul className="list-unstyled m-0 d-flex flex-column justify-content-center">
              <li>
                <strong>▷ 기본권한:</strong> 페이지별로 접근만 하는 것, 본인
                데이터만 조정하는 것, 담임만 허용된 것 등
              </li>
              <li>
                <strong>▷ 조정 권한:</strong> 해당 페이지 데이터 전체를 다룰 수
                있습니다.
              </li>
              <li>
                <strong>▷ 추천 방법:</strong> 보통은 기본권한만 주고, 관리자,
                교무기획부장 등 특별한 임무를 맡은 사람만 조정권한을 줍니다.
              </li>
            </ul>
          </div>
          {selectedGroup && Object.keys(selectedGroup).length > 0 && (
            <div className="table-responsive">
              <table
                className={`table table-bordered text-center ${styles.thisTable}`}
              >
                <thead>
                  <tr>
                    <th rowSpan={2}>상위메뉴</th>
                    <th rowSpan={2}>페이지</th>
                    <th>본인데이터</th>
                    <th colSpan={4}>
                      해당 페이지의 모든 조정 권한(타인 데이터 등)
                    </th>
                    <th rowSpan={2}>
                      <button
                        className="btn btn-primary"
                        onClick={handleSavePermissions}
                      >
                        {saved ? "완료" : "저장"}
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th
                      title="권한이 없으면 메뉴도 보이지 않습니다."
                      style={{ width: "13%" }}
                    >
                      기본권한(접근)
                      <br />
                      전체
                      <input
                        type="checkbox"
                        className="form-check-input ms-1"
                        onChange={(e) =>
                          handleAllCheckboxChange(
                            "can_access",
                            e.target.checked
                          )
                        }
                      />{" "}
                    </th>
                    <th style={{ width: "13%" }}>
                      보기(Read)
                      <br />
                      전체
                      <input
                        type="checkbox"
                        className="form-check-input ms-1"
                        onChange={(e) =>
                          handleAllCheckboxChange("can_read", e.target.checked)
                        }
                      />
                    </th>
                    <th style={{ width: "13%" }}>
                      생성(Create)
                      <br />
                      전체
                      <input
                        type="checkbox"
                        className="form-check-input ms-1"
                        onChange={(e) =>
                          handleAllCheckboxChange(
                            "can_create",
                            e.target.checked
                          )
                        }
                      />
                    </th>
                    <th style={{ width: "13%" }}>
                      수정(Update)
                      <br />
                      전체
                      <input
                        type="checkbox"
                        className="form-check-input ms-1"
                        onChange={(e) =>
                          handleAllCheckboxChange(
                            "can_update",
                            e.target.checked
                          )
                        }
                      />
                    </th>
                    <th style={{ width: "13%" }}>
                      삭제(Delete)
                      <br />
                      전체
                      <input
                        type="checkbox"
                        className="form-check-input ms-1"
                        onChange={(e) =>
                          handleAllCheckboxChange(
                            "can_delete",
                            e.target.checked
                          )
                        }
                      />
                    </th>
                    {/* <th>
                    그외(extra)
                    <input
                      type="checkbox"
                                            className="form-check-input"

                      onChange={(e) =>
                        handleAllCheckboxChange("extra_cases", e.target.checked)
                      }
                    />
                  </th> */}
                  </tr>
                </thead>
                <tbody>
                  {allMenus &&
                    allMenus
                      .filter(
                        (menu) =>
                          menu.parentcategory !== "parent" &&
                          menu.parentcategory !== "profile" &&
                          menu.user_role !== "admin"
                      )
                      .map((menu, index) => {
                        const perm =
                          permissions.find((p) => p.menunames_id === menu.id) ||
                          {};

                        const highCategory =
                          allMenus.find(
                            (category) =>
                              category.id.toString() ===
                              menu.parentcategory.toString()
                          ) || "";

                        return (
                          <tr key={menu.id}>
                            <td>{highCategory.categoryname}</td>
                            <td>{menu.categoryname || ""}</td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={perm.can_access || false}
                                name="can_access"
                                onChange={() =>
                                  handleCheckboxChange(menu.id, "can_access")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={perm.can_read || false}
                                name="can_read"
                                onChange={() =>
                                  handleCheckboxChange(menu.id, "can_read")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={perm.can_create || false}
                                name="can_create"
                                onChange={() =>
                                  handleCheckboxChange(menu.id, "can_create")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={perm.can_update || false}
                                name="can_update"
                                onChange={() =>
                                  handleCheckboxChange(menu.id, "can_update")
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={perm.can_delete || false}
                                name="can_delete"
                                onChange={() =>
                                  handleCheckboxChange(menu.id, "can_delete")
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
